<template>
  <div class="tag-modal-container">
    <p-dialog
      header="Edit tags"
      :visible="modalVisible"
      :style="{ width: '50vw' }"
      :modal="true"
      :dismissableMask="true"
      :closable="false"
    >
      <div class="tag-modal-autocomplete">
        <p>Select tags you want:</p>
        <p-autocomplete
          class="autocomplete"
          :multiple="true"
          v-model="initialTagTemplates"
          :suggestions="filteredTags"
          field="name"
          @complete="searchTags($event)"
        />
      </div>
      <template #footer>
        <p-button
          label="Cancel"
          icon="pi pi-times"
          @click="cancelTagModal()"
          class="p-button-text"
        />
        <p-button
          label="Update"
          icon="pi pi-check"
          @click="createTags()"
          autofocus
        />
      </template>
    </p-dialog>
  </div>
</template>

<script>
import axios from "axios";
import tagsMixin from './mixins/tagsMixin';

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";

export default {
  mixins: [tagsMixin],
  components: {
    "p-dialog": Dialog,
    "p-button": Button,
    "p-autocomplete": AutoComplete,
  },
  props: {
    modalVisible: {
      required: true,
      type: Boolean,
    },
    tags: {
      required: true,
      type: Array,
    },
    parentType: {
      required: false,
      type: String,
    },
    parentId: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      suggestedTags: [],
      filteredTags: null,
      initialTags: [],
      initialTagTemplates: [],
    };
  },
  methods: {
},
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.tag-modal-container {
  ::v-deep {
    .p-dialog {
      height: 35%;
      width: 30%;
    }
    .p-dialog-mask {
      overflow: scroll;
    }
    .p-dialog-content {
      height: 30vw;
      border: none;
      padding: 25px 30px;
    }

    .p-dialog-titlebar {
      position: relative;
      background-color: white;
      height: 75px;
      padding: 0 2rem;
      border: none;
      border-bottom: 1px solid #c8c8c8;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      h3 {
        font-weight: 600;
        font-size: 25px;
        margin-top: 28px;
      }
    }

    .p-dialog-footer {
      border: none;
      border-top: 1px solid #c8c8c8;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
