<template>
  <span>
    <span v-for="contact in contactList">
      <contact-pill :currentContact="contact" @remove-contact="removeContact"></contact-pill>
    </span>
    <i class="add-contact pi pi-plus-circle" @click="openEditModal"></i>

    <p-dialog
      header="New Contact"
      :visible.sync="editModalVisible"
      :style="{width: '50vw'}"
      :modal="true"
      v-click-modal-outside="closeEditModal"
    >
      <contact-form
        :initial-contact="newContact"
        :should-create="shouldCreate"
        @add-new-success="onAddNewSuccess"
      ></contact-form>

      <template #footer>
        <p-button label="Cancel" icon="pi pi-times" @click="closeEditModal" class="p-button-text"/>
        <p-button label="Save" icon="fas fa-save" @click="saveNewModal" autofocus />
      </template>
    </p-dialog>
  </span>
</template>

<script>
import ContactForm from "./_form";
import ContactPill from "./_pill";

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';

export default {
  components: {
    'contact-form': ContactForm,
    'contact-pill': ContactPill,
    'p-button': Button,
    'p-dialog': Dialog,
    'p-tag': Tag
  },
  data() {
    return {
      editModalVisible: false,
      shouldCreate: false,
      newContact: {
        'contactable_id': this.contactableId,
        'contactable_type': this.contactableType
      }
    }
  },
  props: {
    contactList: {
      type: Array,
      required: true
    },
    contactableType: {
      type: String,
      required: false
    },
    contactableId: {
      type: String,
      required: false
    }
  },
  methods: {
    openEditModal: function() {
      this.editModalVisible = true;
    },
    closeEditModal: function() {
      this.editModalVisible = false;
    },
    saveNewModal: function() {
      this.shouldCreate = true;
    },
    onAddNewSuccess: function(event) {
      this.contactList.push(JSON.parse(JSON.stringify(event)));
      this.closeEditModal();
    },
    removeContact: function(event) {
      this.contactList = this.contactList.filter(c => c['id'] != event);
    }
  }
}
</script>

<style>
  .add-contact {
    font-size: 20px;
    position: absolute;
    top: 5%;
  }
</style>