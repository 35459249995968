<template>
  <div class="accordion-form-wrapper d-flex flex-column mx-3">
    <div class="row">
      <div class="float-label col-md-6">
        <label>Start Date</label>
        <p-calendar
          class="input-display-text"
          dateFormat="MM dd, yy"
          :disabled="!editable"
          v-model="startDate"
        ></p-calendar>
      </div>
      <div class="float-label col-md-6">
        <label>Contact Term</label>
        <p-input-text
          class="input-display-text"
          placeholder="N/A"
          :disabled="!editable"
          v-model="contractInformation['contract_term']"
        ></p-input-text>
      </div>
    </div>
    <div class="row">
      <div class="float-label col-md-6">
        <label>VSU Subscription Units</label>
        <p-input-text
          class="ml-2 input-display-text"
          :disabled="!editable"
          placeholder="N/A"
          v-model="contractInformation['vsu_subscription_units']">
        </p-input-text>
      </div>
      <div class="float-label col-md-6">
        <label>Clinical Users</label>
        <p-input-text
          class="ml-2 input-display-text"
          :disabled="!editable"
          placeholder="N/A"
          v-model="contractInformation['clinical_users']">
        </p-input-text>
      </div>
    </div>
    <div class="row">
      <div class="float-label col-md-6">
        <label>Renewal Timeline/Term</label>
        <p-input-text
          class="ml-2 input-display-text"
          :disabled="!editable"
          placeholder="N/A"
          v-model="contractInformation['renewal_timeline']">
        </p-input-text>
      </div>
      <div class="float-label col-md-6">
        <label>Contract Signatory</label>
        <p-input-text
          class="ml-2 input-display-text"
          :disabled="!editable"
          placeholder="N/A"
          v-model="contractInformation['contract_signatory']">
        </p-input-text>
      </div>
    </div>
    <div class="row">
      <div class="float-label col-md-6">
        <label>Role</label>
        <p-input-text class="ml-2 input-display-text" :disabled="!editable" v-model="contractInformation['role']"></p-input-text>
      </div>
    </div>
    <div class="row">
      <div class="float-label col-md-12">
        <label>Other Relevant Contract Details</label>
        <p-textarea :autoResize="true" class="ml-2 input-display-text" rows="5" :disabled="!editable" v-model="contractInformation['other_relevant_contract_details']"></p-textarea>
      </div>
    </div>
    <div class="row">
      <div class="float-label col-md-12">
        <label>Additional Information:</label>
        <p-textarea :autoResize="true" class="ml-2 input-display-text" rows="5" :disabled="!editable" v-model="contractInformation['notes']"></p-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';

export default {
  components: {
    'p-button': Button,
    'p-input-text': InputText,
    'p-calendar': Calendar,
    'p-textarea': Textarea,
  },
  data: function() {
    return {
      startDate: '',
      contractInformation: this._.clone(this.initialContract),
      users: []
    }
  },
  props: {
    initialContract: {
      type: Object,
      required: true
    },
    subscriptionId: {
      type: Number,
      default: -1
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    save() {
      if (this.subscriptionId > 0) this.contractInformation["subscription_id"] = this.subscriptionId;
      this.contractInformation['start_date'] = this.parseDate(this.startDate)
      if (Object.keys(this.initialContract).length === 0) {
        axios.post(`/contract_informations`, this.contractInformation)
          .then((res) => {
            this.$toast.add({severity:'success', summary: 'Success', detail:'Contract Information record updated.', life: 3000});
            this.contractInformation = res.data.contract_information;
            this.$emit('save-success', this.contractInformation);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Contract Information could not be updated', life: 3000});
            this.$emit('save-fail', error);
          })
      } else {
        axios.patch(`/contract_informations/${this.contractInformation.id}`, this.contractInformation)
          .then((res) => {
            this.$toast.add({severity:'success', summary: 'Success', detail:'Contract Information record updated.', life: 3000});
            this.contractInformation = res.data.contract_information;
            this.$emit('save-success', this.contractInformation);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Contract Information could not be updated', life: 3000});
            this.$emit('save-fail', error);
          })
      }
    },
    reset() {
      this.contractInformation = this._.clone(this.initialContract);
      this.$emit('close');
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    },
    parseStringToDate(stringDate){
      if(stringDate) {
        return new Date(stringDate);
      } else {
        return new Date();
      }
    },
    displayValue(value) {
      return value === null? 'N/A' : value
    }
  },
  mounted() {
    this.startDate = this.parseStringToDate(this.initialContract['start_date'])
  },
}
</script>