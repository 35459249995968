var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datatable"},[_c('p-datatable',{attrs:{"value":_vm.implementations,"paginator":true,"rows":10,"filters":_vm.filters,"filter-display":"menu","paginatorPosition":"top","paginatorTemplate":"RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink","rowsPerPageOptions":[10, 25, 50],"currentPageReportTemplate":"{first} - {last} of {totalRecords}"},scopedSlots:_vm._u([{key:"FirstPageLink",fn:function(){return [_c('p-button',{attrs:{"type":"button","icon":"pi pi-search"}})]},proxy:true},{key:"header",fn:function(){return [_c('span',{staticClass:"p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_vm._v(" "),_c('p-inputtext',{attrs:{"placeholder":"Start typing to search"},model:{value:(_vm.filters['global'].value),callback:function ($$v) {_vm.$set(_vm.filters['global'], "value", $$v)},expression:"filters['global'].value"}})],1),_vm._v(" "),_c('p-button',{attrs:{"label":"+ Add Implementation"},on:{"click":_vm.openAddImplementationModal}})]},proxy:true}])},[_vm._v(" "),_vm._v(" "),_c('p-column',{attrs:{"field":"implementation","header":"Implementation","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('a',{attrs:{"href":("/application_groups/" + (data.implementationId))}},[_vm._v(_vm._s(data.implementation))])]}}])}),_vm._v(" "),_c('p-column',{attrs:{"field":"applications","header":"Applications","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _vm._l((data.applications),function(application,key){return _c('span',{key:key,class:'datatable-badge app-' + application.name},[_vm._v("\n          "+_vm._s(application.name)+"\n        ")])})}}])}),_vm._v(" "),_c('p-column',{attrs:{"field":"tags","header":"Tags","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _vm._l((data.tags),function(tag,key){return _c('span',{key:key,staticClass:"datatable-badge",style:({ backgroundColor: '#' + tag.color })},[_vm._v("\n          "+_vm._s(tag.name)+"\n        ")])})}}])}),_vm._v(" "),_c('p-column',{attrs:{"field":"subscription","header":"Subscription","sortable":""}}),_vm._v(" "),_c('p-column',{scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('p-button',{staticClass:"edit-button",attrs:{"type":"button","icon":"pi pi-pencil"},on:{"click":function($event){return _vm.openEditImplementationModal(data)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }