<template>
  <div class="d-flex flex-column mx-3">
    <div class="row w-100">
      <div class="float-label col-md-12">
        <label>Contracted Support/Service Request Hours:</label>
        <div class="d-flex flex-row align-items-center">
          <p-calendar
            class="input-display-text"
            v-model="supportInformation['contracted_support']['time_from']"
            :timeOnly="true"
            hourFormat="12"
            :disabled="!editable"
          />
          <span class="mx-2">to</span>
          <p-calendar
            class="input-display-text"
            v-model="supportInformation['contracted_support']['time_to']"
            :timeOnly="true"
            hourFormat="12"
            :disabled="!editable"
          />
          <p-input-text
            class="mx-2 input-display-text"
            :options="['EST']"
            v-model="supportInformation['contracted_support']['timezone']"
            :disabled="true"
          ></p-input-text>
          <p-dropdown
            class="input-display-text"
            :options="days"
            v-model="supportInformation['contracted_support']['from_day']"
            :disabled="!editable"
          ></p-dropdown>
          <span class="mx-2">to</span>
          <p-dropdown
            class="input-display-text"
            :options="days"
            v-model="supportInformation['contracted_support']['to_day']"
            :disabled="!editable"
          ></p-dropdown>
        </div>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="float-label col-md-12">-->
<!--        <label>Availability SLA:</label>-->
<!--        <p-input-text-->
<!--          class="input-display-text"-->
<!--          v-model="supportInformation['availability_sla']"-->
<!--          :disabled="!editable"-->
<!--        ></p-input-text>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="float-label col-md-12">-->
<!--        <label>Resolution SLA:</label>-->
<!--        <p-textarea-->
<!--          class="input-display-text"-->
<!--          v-model="supportInformation['resolution_sla']"-->
<!--          :disabled="!editable"-->
<!--        ></p-textarea>-->
<!--      </div>-->
<!--    </div>-->
    <div class="float-label">
      <label>Additional Information:</label>
      <p-textarea
        class="input-display-text w-100"
        v-model="supportInformation['notes']"
        :disabled="!editable"
      ></p-textarea>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    "p-button": Button,
    "p-input-text": InputText,
    "p-calendar": Calendar,
    "p-textarea": Textarea,
    "p-dropdown": Dropdown,
    "p-overlay-panel": OverlayPanel,
    "p-datatable": DataTable,
    "p-column": Column,
    "p-autocomplete": AutoComplete,
  },
  data: function () {
    return {
      supportInformation: this._.clone(this.initialSupportInformation),
      users: [],
      days: ["MON", "TUE", "WED", "THURS", "FRI", "SAT", "SUN"],
      // bug in Vue that it doesn't accept numbers as labels
      // https://github.com/primefaces/primeng/issues/9870
    };
  },
  props: {
    initialSupportInformation: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
      default: [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    save() {
      if (this.subscription.id > 0)
        this.supportInformation["subscription_id"] = this.subscription.id;
      if (this.isEmpty()) {
        return axios
          .post(`/support_informations`, this.supportInformation)
          .then((res) => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Support Information record updated.",
              life: 3000,
            });
            this.supportInformation = res.data.support_information;
            this.$emit("save-success", this.supportInformation);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Support Information could not be updated",
              life: 3000,
            });
            this.$emit("save-fail", error);
          });
      } else {
        return axios
          .patch(
            `/support_informations/${this.supportInformation.id}`,
            this.supportInformation
          )
          .then((res) => {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Support Information record updated.",
              life: 3000,
            });
            this.supportInformation = res.data.support_information;
            this.$emit("save-success", this.supportInformation);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Support Information could not be updated",
              life: 3000,
            });
            this.$emit("save-fail", error);
          });
      }
    },
    reset() {
      this.$emit("close");
    },
    parseDate(date) {
      return new Date(date).toLocaleTimeString();
    },
    displayValue(value) {
      return value === null || value === undefined ? "N/A" : value;
    },
    isEmpty() {
      // initial object has only two keys in it to initialize nested fields
      return Object.keys(this.initialSupportInformation).length === 2;
    },
    convertTimeStringToTime() {
      this.supportInformation["contracted_support"]["time_from"] = this
        .initialSupportInformation["contracted_support"]["time_from"]
        ? new Date(
            this.initialSupportInformation["contracted_support"]["time_from"]
          )
        : new Date(2000, 1, 1, 8, 0, 0);
      this.supportInformation["contracted_support"]["time_to"] = this
        .initialSupportInformation["contracted_support"]["time_to"]
        ? new Date(
            this.initialSupportInformation["contracted_support"]["time_to"]
          )
        : new Date(2000, 1, 1, 18, 0, 0);
    },
    setDefaultData() {
      this.supportInformation["availability_sla"] ||= "99% monthly uptime";
      this.numOfAuthUsers =
        this.initialSupportInformation["allowed_authorized_users"];
    },
  },
  watch: {
    supportInformation() {
      this.convertTimeStringToTime();
    },
    initialSupportInformation: function () {
      this.supportInformation = this._.clone(this.initialSupportInformation);
      this.setDefaultData();
    },
  },
  mounted() {
    this.convertTimeStringToTime();
    this.setDefaultData();
  },
};
</script>

<style lang="scss" scoped>
.auth-user-row {
  div {
    padding: 0 1rem 0 0;
  }

  .p-button {
    background-color: var(--red-200);
    border-color: var(--red-200);
  }
}
</style>