<template>
  <div>
    <h3>Reservations TBD</h3>
  </div>
</template>

<script>
import ReportMixin from './_report_mixin'

import Datatable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import moment from 'moment';

export default {
  mixins: [ReportMixin],
  components: {
    'p-datatable': Datatable,
    'p-column': Column,
    'p-tag': Tag,
  },
  data() {
    return {
      moment
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

</style>