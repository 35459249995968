<template>
  <div class="wrapper">
    <div class="c-stepper-container">
      <ol class="c-stepper">
        <li class="c-stepper-item" :class="{ 'c-stepper-active': currentComponent.index === 0 }">
          <span class="c-stepper-icon align-items-center justify-content-center">1</span>
          <h3 class="c-stepper-title">Select Provider</h3>
        </li>
        <li class="c-stepper-item" :class="{ 'c-stepper-active': currentComponent.index === 1 }">
          <span class="c-stepper-icon align-items-center justify-content-center">2</span>
          <h3 class="c-stepper-title">Configure Resource</h3>
        </li>
        <li class="c-stepper-item" :class="{ 'c-stepper-active': currentComponent.index === 2 }">
          <span class="c-stepper-icon align-items-center justify-content-center">3</span>
          <h3 class="c-stepper-title">Details and Confirmation</h3>
        </li>
      </ol>
    </div>

    <transition name="component-fade" mode="out-in">
      <component
          v-if="currentComponent"
          :is="currentComponent.component"
          @transition="handleTransition"
          :initial-configuration-item="this._.clone(configurationItem)"
          @update-model="handleModelUpdate"
          @wizard-complete="$emit('wizard-complete', $event)"
      ></component>
    </transition>

  </div>
</template>

<script>
import stepOne from './_stepOne'
import stepTwoAzureResource from './_stepTwoAzureResource'
import stepThree from './_stepThree'

export default {
  components: {
    stepOne,
    stepTwoAzureResource
  },
  data() {
    return {
      steps: {
        landing: {
          component: stepOne,
          index: 0,
          next: {
            azureResource: 'azureResource'
          }
        },
        azureResource: {
          component: stepTwoAzureResource,
          index: 1,
          next: {
            back: 'landing',
            reviewAzureResource: 'reviewAzureResource'
          }
        },
        reviewAzureResource: {
          component: stepThree,
          index: 2,
          next: {
            azureResource: 'azureResource'
          }
        }
      },
      currentComponent: { index: 0 },
      configurationItem: { application_configuration_items_attributes: [ { application_id: this.application_id } ] }
    }
  },
  props: {
    application_id: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleTransition: function(event) {
      this.currentComponent = this.steps[this.currentComponent.next[event]];
    },
    handleModelUpdate: function(event) {
      this.configurationItem = event;
    }
  },
  mounted() {
    this.currentComponent = this.steps.landing
  }
}
</script>

<style lang="scss" scoped>

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .1s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.c-stepper {
  display: flex;
  padding: 0;
}

.c-stepper-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &.c-stepper-active {
    .c-stepper-icon {
      background-color: #0d6efd;
      color: white;
      opacity: 100%;
    }

    .c-stepper-title {
      opacity: 100%;
    }
  }

  .c-stepper-title {
    font-weight: bold;
    font-size: 16px;
    opacity: 50%;
  }

  .c-stepper-icon {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: black;
    font-weight: bold;
    background-color: #d3d3d3;
    margin: 0 auto 16px;
    opacity: 50%;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(40px / 2);
      width: calc(100% - 40px - 20px);
      left: calc(50% + calc(40px / 2 + 10px));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}
</style>
