<script>
import TabView from 'primevue/tabview';

/* https://stackoverflow.com/questions/68002455/prevent-tab-change-on-primevue-tabview-component
 * Put this after you import TabView.
 * This will prevent automatic tab switching but still emits
 * the event to your application.
 */
let customTabView = _.cloneDeep(TabView)
customTabView.methods.onTabClick = function (event, i) {
  this.$emit("tab-click", {
    originalEvent: event,
    index: i,
  });
};

export default customTabView;
</script>