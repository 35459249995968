
<template>
  <div class="p-3">
    <div>
    <p-textarea
      class="input-display-text w-100"
      v-model="notes"
      :disabled="!editable"
      :autoResize="true"
      rows="5" 
      cols="120"
    ></p-textarea>

  </div>
      <div v-if="editable" class="d-flex p-3 fixed-bottom flex-row-reverse w-100 buttons-container">
      <p-button class="save-btn" label="Save" @click="save"></p-button>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';

export default {
  components: {
    "p-textarea": Textarea,
    "p-button": Button,
  },
  data() {
    return {
      notes: this.initialNotes,
      allowedNoteTypes: [
        'key_contact_notes',
        'implementation_notes',
        'additional_material_notes',
      ]
    }
  },
  props: {
    initialNotes: {
      type: String,
      default: ''
    },
    noteType: {
      type: String,
      required: true
    },
    subscriptionId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    save() {
      let subscription = {};
      subscription[this.noteType] = this.notes;
      axios.patch(`/subscriptions/${this.subscriptionId}`, subscription)
      .then((res) => {
        this.$toast.add({severity:'success', summary: 'Success', detail:'Saved notes.', life: 3000});
        this.notes = res.data.subscription?.[this.noteType];
        this.$emit('save-notes-success', res.data.subscription,this.noteType)
      })
      .catch((error) => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Couldn\'t save notes.', life: 3000});
        if(!this.allowedNoteTypes.includes(this.noteType)) {
          this.$toast.add({severity:'error', summary: 'Error', detail:'Invalid note type.', life: 3000});
        }
      })
    }
  },
  watch:{
    initialNotes: function() {
      this.notes = this.initialNotes;
    }
  }
}
</script>