<template>
  <div class="m-2 py-2">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <overview-box :initialTags="tags">
      <template v-slot:title>
        <div>
          <div class="d-flex justify-content-between edit-icon">
            {{ client.name }}
            <a :href="`/clients/${client.id}/edit`">
              <p-button
                class="edit-icon p-button-lg"
                icon="fas fa-edit"
              ></p-button>
            </a>
          </div>
        </div>
      </template>

     <template v-slot:metadata>
<!--       <div>-->
<!--         <span class="me-6"><b>Org code:</b> <code>{{ initialClient.identifier }}</code></span>-->
<!--         <span><b>Org ID:</b> <code>{{ initialClient.external_id }}</code></span>-->
<!--       </div>-->
       <strong>Group since:</strong> <parsed-date :datetime="client.start_date" :tooltip="false"></parsed-date>
      </template>

      <template v-slot:extraInfo>
        {{ client.description }}
      </template>
    </overview-box>

    <div class="my-4">
      <div class="d-flex flex-row">

      </div>
      <tab-view
        :key="subscriptionTabsKey"
        :activeIndex="currentSubscriptinIndex"
        :preventDefault="true"
        @tab-click="addSubscription"
      >
        <p-tab-panel
          v-for="subscription in client.subscriptions"
          :key="subscription.id"
        >
          <template #header>
            {{ subscription.name }}
          </template>
          <subscription
            :initialClient="client"
            :initialSubscription="subscription"
          ></subscription>
        </p-tab-panel>
        <p-tab-panel>
          <template #header>
            <p-button
              class="add-sub-button"
              icon="pi pi-plus"
              style="font-size: 1rem"
            ></p-button>
          </template>
        </p-tab-panel>
      </tab-view>
    </div>
    <p-dialog
      header="New subscription"
      :visible.sync="addSubscriptionModalVisisble"
      :modal="true"
      :style="{ width: '50vw' }"
      v-click-modal-outside="closeAddSubscriptionModal"
    >
      <div class="float-label">
        <label>Subscription Name:</label>
        <p-inputtext v-model="newSubscription.name"></p-inputtext>
      </div>
      <div class="float-label">
        <label>Subscription Description:</label>
        <p-textarea v-model="newSubscription.description"></p-textarea>
      </div>
      <div class="d-flex flex-row">
        <span class="float-label w-100 me-2">
          <label>Subscription Identifier:</label>
          <p-inputtext v-model="newSubscription.identifier"></p-inputtext>
        </span>
        <span class="float-label w-100 me-2">
          <label>Subscription Hubspot ID:</label>
          <p-inputtext v-model="newSubscription.external_id"></p-inputtext>
        </span>
<!--        <span class="float-label w-100 ms-2">-->
<!--          <label>Subscription Dev Code:</label>-->
<!--          <p-inputtext v-model="newSubscription.dev_code"></p-inputtext>-->
<!--        </span>-->
      </div>
      <div class="float-label">
        <label>Status:</label>
        <p-dropdown
          v-model="newSubscription.status"
          :options="initialStatuses"
          optionLabel="label"
          optionValue="value"
          class="w-100"
        ></p-dropdown>
      </div>
      <template #footer>
        <p-button
          class="cancel-btn"
          label="Cancel"
          @click="resetSubscription"
        ></p-button>
        <p-button
          class="save-btn"
          label="Save"
          @click="saveSubscription"
        ></p-button>
      </template>
    </p-dialog>

    
    <!-- devops: all implemenatations -->
    <!--
    <div id="allImplementations" class="my-4">
      <h5>Implementations</h5>
      <implementations-table
        :implementations="implementations"
        @open-add-implementation-modal="openAddImplementationModal"
        @open-edit-implementation-modal="openEditImplementationModal"
      />
    </div> -->

    <p-dialog
      header="Add Implementation to Client"
      :visible.sync="addImplementationModalVisible"
      :modal="true"
      :style="{ width: '50vw', height: '50vh' }"
    >
      <div class="float-label">
        <label>Implementation Name:</label>
        <p-inputtext v-model="newImplementation.name"></p-inputtext>
      </div>
      <div class="float-label">
        <label>Applications:</label>
        <p-autocomplete
          class="autocomplete"
          :multiple="true"
          v-model="newImplementation.applications_attributes"
          :suggestions="filteredApplications"
          field="name"
          @complete="searchApplication($event)"
        />
      </div>
      <div class="float-label">
        <label>Subscriptions:</label>
        <p-dropdown
          v-model="newImplementation.subscription_id"
          :options="subscriptions"
          optionLabel="label"
          optionValue="id"
          class="w-100"
        ></p-dropdown>
      </div>
      <template #footer>
        <p-button
          class="form-cancel-btn"
          label="Cancel"
          @click="resetImplementation"
        ></p-button>
        <p-button
          class="form-add-btn"
          label="Add"
          @click="saveImplementation"
        ></p-button>
      </template>
    </p-dialog>

    <p-dialog
      header="Edit Implementation"
      :visible.sync="editImplementationModalVisible"
      :modal="true"
      :style="{ width: '50vw', height: '50vh' }"
    >
      <implementation-form
        :initialImplementation="implementationToEdit"
        :initialSubscriptions="subscriptions"
        @save-edited-implementation="saveEditedImplementation"
        @delete-implementation="deleteImplementation"
      ></implementation-form>
    </p-dialog>
  </div>
</template>

<script>
import axios from "axios";

import OverviewBox from "../../components/overviewBox";
import parsedDate from "../../components/parsedDate";
import TagSection from "../../components/tagSection.vue";
import Breadcrumbs from "../../components/styledBreadcrumb.vue";
import ImplementationsTable from "../../components/implementationsTable.vue";
import Subscription from "../subscriptions/show.vue";
import ImplementationForm from "../implementations/_form.vue"

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import TextArea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";

import CustomTabView from "../../components/customTabView.vue";

export default {
  components: {
    "overview-box": OverviewBox,
    "implementations-table": ImplementationsTable,
    'implementation-form': ImplementationForm,
    "tab-view": CustomTabView,
    "subscription": Subscription,
    "parsed-date": parsedDate,
    "breadcrumbs": Breadcrumbs,
    "p-button": Button,
    "p-dialog": Dialog,
    "p-tab-panel": TabPanel,
    "p-inputtext": InputText,
    "p-textarea": TextArea,
    "p-dropdown": Dropdown,
    "p-autocomplete": AutoComplete,
    TagSection,

  },
  data() {
    return {
      tags: [],
      client: this.initialClient,
      breadcrumbs: [
        {
          label: "Groups",
          url: "/clients",
        },
        {
          label: this.initialClient.name,
        },
      ],
      initialStatuses: [
        { label: "Active", value: "active" },
        { label: "Pending", value: "pending" },
        { label: "Inactive", value: "inactive" },
      ],
      suggestedApplications: [
        { name: "Flow", description: "Flow" },
        { name: "Automate", description: "Automate" },
        { name: "Engage", description: "Engage" },
        { name: "Journey", description: "Journey" },
      ],
      filteredApplications: [],
      currentSubscriptinIndex: 0,
      implementations: [],
      addSubscriptionModalVisisble: false,
      addImplementationModalVisible: false,
      editImplementationModalVisible: false,
      implementationToEdit: {},
      newSubscription: {
        name: "",
        description: "",
        status: "",
        external_id: null,
        identifier: "",
        dev_code: "",
      },
      newImplementation: {
        name: "",
        applications_attributes: [],
        subscription: "",
        subscription_id: "",
      },
      subscriptionTabsKey: 0,
      subscriptions: [],
    };
  },
  props: {
    initialClient: {
      type: Object,
      require: true,
    },
    initialSubscriptionId: {
      type: String,
      default: "-1",
    },
  },
  methods: {
    searchApplication(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredApplications = [...this.suggestedApplications];
        } else {
          this.filteredApplications = this.suggestedApplications.filter(
            (application) => {
              return application.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            }
          );
        }
      }, 250);
    },
    getApplicationGroups() {
      let implementations_arr = [];
      let subscriptions_arr = [];
      this.client.subscriptions.map((subscription) => {
        subscriptions_arr.push({
          label: subscription.name,
          id: subscription.id,
        });
        subscription.application_groups.map((application_group) => {
          implementations_arr.push({
            subscriptionId: subscription.id,
            implementationId: application_group.id,
            subscription: subscription.name,
            implementation: application_group.name,
            tags: application_group.tag_templates,
            applications: application_group.applications,
          });
        });
      });

      this.implementations = implementations_arr;
      this.subscriptions = subscriptions_arr;
    },
    remountSubscriptionTabs() {
      this.subscriptionTabsKey = !this.subscriptionTabsKey;
    },
    openAddSubscriptionModal() {
      this.addSubscriptionModalVisisble = true;
    },
    closeAddSubscriptionModal() {
      this.addSubscriptionModalVisisble = false;
    },
    addSubscription(event) {
      if (event) {
        if (event.index < this.client.subscriptions.length) {
          this.currentSubscriptinIndex = event.index;
          this.closeAddSubscriptionModal();
          // preserve current subscription on refresh
          const newId = this.client.subscriptions[this.currentSubscriptinIndex].id;
          var refresh = window.location.protocol + "//" +
            window.location.host + window.location.pathname +
            `?subscription=${newId}`;    
          window.history.pushState({ path: refresh }, '', refresh);
          // update localStorage so that when user returns to page with no subscription id,
          // defaults to last visited subscription
          localStorage.setItem('subscriptionId', newId);

        } else {
          this.openAddSubscriptionModal();
        }
      }
    },
    saveSubscription() {
      axios
        .post("/subscriptions", {
          client: { id: this.client.id },
          subscription: this.newSubscription,
        })
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Created Subscription.",
            life: 3000,
          });
          this.client.subscriptions.push(res.data.subscription);
          this.remountSubscriptionTabs();
          this.resetSubscription();
        })
        .catch((error) => {
          let errMsg = `${Object.keys(error.response.data.errors)[0]}: ${
            Object.values(error.response.data.errors)[0]
          }`;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: errMsg,
            life: 3000,
          });
        });
    },
    resetSubscription() {
      this.newSubscription = {
        name: "",
        description: "",
        status: "",
      };
      this.closeAddSubscriptionModal();
    },
    fetchSingleClient() {
      axios
        .get(`/clients/${this.client.id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.client = res.data.client;
          this.getApplicationGroups();
        })
        .catch((err) => {
          // TODO: error popup
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "error during processing fetched clients",
            life: 3000,
          });
        });
    },
    saveImplementation() {
      axios
        .post("/application_groups", this.newImplementation)
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Created Implementation.",
            life: 3000,
          });
          this.resetImplementation();
          this.fetchSingleClient();
          this.$forceUpdate();
        })
        .catch((error) => {
          let errMsg = `${Object.keys(error.response.data.errors)[0]}: ${
            Object.values(error.response.data.errors)[0]
          }`;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: errMsg,
            life: 3000,
          });
        });
    },
    saveEditedImplementation(implementation) {
      axios
        .patch(`/application_groups/${implementation.id}`, implementation)
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Updated Implementation.",
            life: 3000,
          });
          this.resetImplementation();
          this.fetchSingleClient();
          this.closeEditImplementationModal();
          this.$forceUpdate();
        })
        .catch((error) => {
          let errMsg = `${Object.keys(error.response.data.errors)[0]}: ${
            Object.values(error.response.data.errors)[0]
          }`;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: errMsg,
            life: 3000,
          });
        });
    },
    deleteImplementation(implementation) {
      axios
        .delete(`/application_groups/${implementation.id}`)
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Deleted ${implementation.name}`,
            life: 3000,
          });
          this.resetImplementation();
          this.fetchSingleClient();
          this.closeEditImplementationModal();
          this.$forceUpdate();
        })
        .catch((error) => {
          let errMsg = `${Object.keys(error.response.data.errors)[0]}: ${
            Object.values(error.response.data.errors)[0]
          }`;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: errMsg,
            life: 3000,
          });
        });
    },
    openAddImplementationModal() {
      this.addImplementationModalVisible = true;
    },
    closeAddImplementationModal() {
      this.addImplementationModalVisible = false;
    },
    openEditImplementationModal(implementation) {
      this.implementationToEdit = {
        id: implementation.implementationId,
        name: implementation.implementation,
        subscription_id: implementation.subscriptionId
      };
      this.editImplementationModalVisible = true;
    },
    closeEditImplementationModal() {
      this.editImplementationModalVisible = false;
    },
    resetImplementation() {
      this.newImplementation = {
        name: "",
        applications_attributes: [],
        subscriptions: "",
      };
      this.closeAddImplementationModal();
    },
    findSubscriptionTabIndex(id) {
      let index = this.client.subscriptions.findIndex(
        (sub) => sub.id == id
      );
      return index;
    },
  },
  mounted() {
    this.currentSubscriptinIndex = 0;
    if (this.initialSubscriptionId !== "-1") {
      // Hack so that tab exists when the initalAppIndex is set. Otherwise
      // TabView component will error out trying to scroll into non existing
      // element
      setTimeout(() => {
        let id = this.initialSubscriptionId ? this.initialSubscriptionId : Number(localStorage.getItem('subscriptionId'));
        let index = this.findSubscriptionTabIndex(id);
        if (index > -1) {
          this.currentSubscriptinIndex = index;
        }
      });
    }
    this.getApplicationGroups();
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .add-sub-button {
    &.p-button {
      background-color: #e4e6f5;
      color: black;
      width: 2rem;
      height: 2rem;
      border: 0px;
    }
  }

  ::v-deep {
    .p-tabview .p-tabview-panels {
      border: solid 1px #f2f3f9;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      border-radius: 8px 8px 0px 0px;
      border-bottom: 4px solid #0d3ef0;
      background-color: #e9f7ff;
      color: #0d3ef0;
      padding: 1rem 2rem;
    }
  }

  ::v-deep .edit-icon {
    &.p-button {
      background-color: inherit;
      color: #007bff;
      border: 0px;
    }

    a {
      text-decoration: inherit;
    }
  }
</style>
