<template>
  <div>
    <p-card>
      <template #title>
        Review and Submit
      </template>
      <template #content>
        <div class="row">
          <div class="col-md-3 text-end align-self-center lh-1">
            <div class="fw-bold">Configuration Item Name*</div>
          </div>
          <div class="col-md-9"><p-input-text class="w-100" type="text" v-model="configurationItem.name" /></div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3 text-end align-self-center lh-1">
            <div class="fw-bold">Configuration Item Description</div>
            <small class="text-secondary">Brief description of CI use</small>
          </div>
          <div class="col-md-9"><p-input-text class="w-100" type="text" v-model="configurationItem.description" /></div>
        </div>


        <div v-if="configurationItem.selectedProvider === 'azureResource'" class="row mt-2">
          <div class="col-md-3 text-end align-self-center lh-1">
            <div class="fw-bold">Azure Resource Identifier</div>
            <small class="text-secondary">Azure Resource URI</small>
          </div>
          <div class="col-md-9">
            <p-input-text class="w-100" type="text" v-model="configurationItem.azure_resource_identifier" disabled/>
          </div>
        </div>

        <div v-if="configurationItem.selectedProvider === 'genericCi'" class="row mt-2">
          <div class="col-md-3 text-end align-self-center lh-1">
            <div class="fw-bold">Configuration Item Type</div>
            <small class="text-secondary">Available only for generic configuration items</small>
          </div>
          <div class="col-md-9">
            <p-input-text class="w-100" type="text" v-model="configurationItem.configuration_item_type" disabled/>
          </div>
        </div>

        <div v-else class="row mt-2">
          <div class="col-md-3 text-end align-self-center lh-1">
            <div class="fw-bold">Configuration Item Type</div>
            <small class="text-secondary">Autodetected based on selected resource</small>
          </div>
          <div class="col-md-9">
            <p-input-text class="w-100" type="text" v-model="configurationItem.type" disabled/>
          </div>
        </div>

        <div class="mt-2 d-flex justify-content-between">
          <p-button label="Back" icon="far fa-arrow-alt-circle-left" @click="handleBack()" class="p-button-text"/>
          <p-button label="Create Configuration Item" icon="far fa-check-circle" iconPos="right" @click="submitCi"/>
        </div>
      </template>
    </p-card>
  </div>
</template>

<script>
import axios from 'axios';

import Card from 'primevue/card';
import Button from "primevue/button";
import InputText from "primevue/inputtext"

export default {
  components: {
    'p-card': Card,
    'p-button': Button,
    'p-input-text': InputText,
  },
  data: function() {
    return {
      configurationItem: {}
    }
  },
  props: {
    initialConfigurationItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleBack: function() {
      this.$emit('transition', this.configurationItem.selectedProvider)
    },
    emitModel: function() {
      this.$emit('update-model', this.configurationItem)
    },
    submitCi: function() {
      axios.post('/configuration_items/', { configuration_item: this.configurationItem })
        .then((res) => {
          this.configurationItem = res.data.configuration_item;
          this.$toast.add({severity:'success', summary: 'Success', detail:'New configuration item added', life: 3000});
          this.$emit('wizard-complete', this.configurationItem);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({severity:'error', summary: 'Error', detail:'Could not save configuration item', life: 3000});
        })
    }
  },
  mounted() {
    this.configurationItem = this.initialConfigurationItem;
  }
}
</script>
<style lang="scss" scoped>

::v-deep .p-card .p-card-content {
  padding-bottom: 0;
}
</style>