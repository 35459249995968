<template>
  <div class="my-3">
    <p-accordion>
      <p-accordion-tab :header="tabHeader">
      <!-- more than one forms -->
        <div v-if="formNames.length > 1">
          <div v-for="form in formNames" :key="form" class="multi-form-wrapper">
            <label>{{ form }}</label>
            <div class="d-flex flex-row align-items-start justify-content-between w-100">
              <div class="col"><slot :name="form" :editable="false"></slot></div>
              <p-button
                v-if="canEdit"
                class="edit-icon p-button-lg flex-column"
                icon="fas fa-edit"
                @click="openEditModal(form)"></p-button>
            </div>
          </div>
        </div>
      <!-- only one form case -->
        <div v-else class="d-flex flex-row align-items-start justify-content-between w-100">
          <div v-if="empty" class="col">
            <slot name="empty"></slot>
          </div>
          <div v-else class="col">
            <slot></slot>
            <div>
              <slot name="accordionForm" :editable="false"></slot>
            </div>
          </div>
          <p-button
            v-if="canEdit"
            class="edit-icon p-button-lg flex-column"
            icon="fas fa-edit"
            @click="openEditModal
          "></p-button>
        </div>
        <!-- Notes -->
        <div v-if="hasNotes" class="multi-form-wrapper">
          <label>Notes</label>
          <div class="d-flex flex-row align-items-start justify-content-between w-100">
            <slot name="notes" :editable="false"></slot>
            <p-button
              class="edit-icon p-button-lg flex-column"
              icon="fas fa-edit"
              @click="openNotesEditModal()"></p-button>
          </div>
        </div>
      </p-accordion-tab>
    </p-accordion>

    <div class="dialog-styling">
      <p-dialog
        :visible.sync="editModal"
        :modal="true"
      >
        <template #header>
          <h3><slot name="header"></slot></h3>
        </template>
        <slot name="accordionForm" :editable="true"></slot>
      </p-dialog>

      <p-dialog
        :visible.sync="editNotesModal"
        :style="{width: '50vw'}"
        :modal="true"
      >
        <template #header>
          <h3>Edit Notes</h3>
        </template>
        <slot name="edit-notes" :editable="true"></slot>
      </p-dialog>

      <div v-for="(form, index) in formNames" :key="form">
        <p-dialog
          :visible.sync="modalOpen[form]"
          :style="formStyles[index] || {width: '50vw'}"
          :modal="true"
          @update:visible="closeEditModal"
        >
          <template #header>
            <h3><slot :name="form + ` header`"></slot></h3>
          </template>
          <slot :name="form" :editable="true"></slot>
        </p-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  components: {
    "p-accordion": Accordion,
    "p-accordion-tab": AccordionTab,
    "p-button": Button,
    "p-dialog": Dialog,
  },
  data() {
    return {
      formData: {},
      editModal: false,
      editNotesModal: this.openNotesModal,
      formNumbers: 0,
      modalOpen: {},
    }
  },
  props: {
    tabHeader: {
      type: String,
      require: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    empty: {
      type: Boolean,
      default: false
    },
    hasNotes :{ 
      type: Boolean,
      default: false
    },
    // To allow for custom stylings and labels for the accordions/modals
    formConfigs: {
      type: Array,
      default: () => [{}]
    },
    openNotesModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openEditModal(form) {
      if(typeof form === 'string') {
        this.modalOpen[form] = true;
      } else {
        this.editModal = true;
      }
      this.$forceUpdate()
    },
    closeEditModal() {
      this.editModal = false;
      this.formNames.forEach((form) => this.modalOpen[form] = false)
      this.$forceUpdate();
    },
    openNotesEditModal() {
      this.editNotesModal = true;
    },
  },
  mounted() {
    this.formNumbers = this.$children[0].$children[0].$children.length - 1;
    this.formNames.forEach((form) => this.modalOpen[form] = false)
  },
  computed: {
    formNames() {
      return this.formConfigs.map(form => form.name);
    },
    formStyles() {
      return this.formConfigs.map(form => form.style || null);
    } 
  },

  watch: {
    openNotesModal: function(){
      this.editNotesModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  ::v-deep .edit-icon-inverse {
    &.p-button {
      background-color: #007bff;
      color: white;
      border: 0px;
      width: 2rem;
      height: 2rem;
    }
  }

  ::v-deep .dialog-styling {
    .p-dialog-content {
      padding: 0;
      min-height: 30vh;
    }

    .p-dialog {
      .p-dialog-header {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      .p-dialog-footer {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  ::v-deep .p-accordion {
    &.p-accordion-header .p-accordion-header-link {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .multi-form-wrapper {
    border-radius: 8px;
    border: solid 1px #e6e7ea;
    padding: 1rem;
    margin: 2rem 0rem;
    width: 100%;

    label {
      position: absolute;
      margin: 0rem 1rem;
      padding: 0rem 0.5rem;
      margin-top: -30px;
      background-color: white;

    }
  }
</style>