<template>
  <div>
    <h2>Application: {{ application.name }}</h2>
    <application-form :initial-application="application"></application-form>
  </div>
</template>

<script>
import applicationForm from './_form'

export default {
  components: {
    'application-form': applicationForm
  },
  props: {
    application: {
      type: Object,
      require: true
    }
  }
}
</script>