<template>
  <div>
    <h4>TAG MANAGER</h4>
    <div class="lab-settings">
      <div class="lab-settings-content">
        <div class="heading-section">
          <button
            @click="showAddTagModal"
            type="button"
            class="btn header-btn add-tag-btn"
          >
            <i class="fa fa-plus btn-icon" aria-hidden="true"></i>Add New Tag
          </button>
        </div>
        <tag-table
          :results="results"
          :total="totalCount"
          :page-size="pageSize"
          :page-offset="pageOffset"
          :loading="loading"
          v-on:tag-page-change="onPageChange($event)"
          v-on:delete-tag="deleteTag($event)"
          v-on:activate-tag="activateTag($event)"
          v-on:edit-tag="selectTagForEdit($event)"
        ></tag-table>
      </div>
    </div>
    <tag-modal
      :modalVisible="modalVisible"
      :showAddTag="showAddTag"
      :showEditTag="showEditTag"
      :selectedTag="selectedTag"
      v-on:save-add-tag="addTag($event)"
      v-on:save-edit-tag="editTag($event)"
      v-on:tag-modal-close="closeModal($event)"
    ></tag-modal>
  </div>
</template>

<script>
import axios from "axios";
import Dialog from "primevue/dialog";
import TagModal from "./tag-modal";
import TagTable from "./tag-table";

export default {
  components: {
    "p-dialog": Dialog,
    "tag-modal": TagModal,
    "tag-table": TagTable,
  },
  props: {},
  data() {
    return {
      results: [],
      totalCount: 0,
      searchBy: "name",
      searchQuery: "",
      fromDate: null,
      toDate: null,
      page: 0,
      pageSize: 10,
      pageOffset: 0,
      loading: false,
      modalVisible: false,
      showAddTag: false,
      showEditTag: false,
      selectedTag: null,
    };
  },
  methods: {
    showAddTagModal() {
      this.showAddTag = true;
      this.showEditTag = false;
      this.modalVisible = true;
    },
    showEditTagModal() {
      this.showAddTag = false;
      this.showEditTag = true;
      this.modalVisible = true;
    },
    closeModal(event) {
      this.showAddTag = false;
      this.showEditModal = false;
      this.modalVisible = false;
    },
    onPageChange(event) {
      this.loading = true;
      this.page = event.page;
      this.pageOffset = event.first;
      this.loadTags();
    },
    deleteTag(tag) {
      axios
        .delete(`/tag_templates/${tag.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Deactivated Tag",
              life: 3000,
            });
            this.loadTags();
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to deactivate Tag",
            life: 3000,
          });
        });
    },
    selectTagForEdit(tag) {
      this.selectedTag = tag;
      this.showEditTagModal();
    },
    addTag(params) {
      this.loading = true;
      this.modalVisible = false;

      axios
        .post(`/tag_templates`, {
          tag_template: params,
        })
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Tag was successfully created",
            life: 3000,
          });
          this.loadTags();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to create tag",
            life: 3000,
          });
          this.loading = false;
        });
    },
    editTag(params) {
      this.loading = true;
      this.modalVisible = false;
      axios
        .patch(`/tag_templates/${params.id}`, {
          tag_template: params,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Tag was successfully updated",
              life: 3000,
            });
            this.loadTags();
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to update tag",
            life: 3000,
          });
        });
    },
    activateTag(params) {
      this.loading = true;
      this.modalVisible = false;
      axios
        .patch(`/tag_templates/${params.id}`, {
          tag_template: { deleted: false },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Activated Tag",
              life: 3000,
            });
            this.loadTags();
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to activate tag",
            life: 3000,
          });
        });
    },
    loadTags() {
      axios
        .get(`/tag_templates`)
        .then((response) => {
          if (response.data) {
            this.results = response.data.tag_templates;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadTags();
  },
};
</script>
<style lang="scss">
.heading-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .header-btn {
    height: 56px;
    width: 110px;
    margin: 0 5px;
    border-radius: 5px;
  }

  .btn-icon {
    margin-right: 5px;
  }

  .add-tag-btn {
    color: #fff;
    background-color: #0d3ef0;
    width: 150px;
  }
}

.lab-settings {
  &-header {
    display: flex;
    flex-direction: row;
    padding: 0 25px 15px 25px;
    justify-content: space-between;
    margin-top: 8px;
    h2 {
      margin: 0;
    }
  }
  &-breadcrumb {
    margin: 13px 25px 0px 25px;
  }
  &-content {
    padding: 25px;
    background-color: #f5f7fa;
    display: flex;
    flex-direction: column;
  }
  &-sidebar {
    margin: 20px 0;
    min-width: 370px;
  }
  &-list {
    margin: 20px 0 0 20px;
    flex-grow: 1;
  }
}
</style>
