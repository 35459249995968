<template>
  <div class='content-body'>
    <div class="row mb-12">
      <div class="col-md-12">
        <h6>Notes</h6>
        <p-text-area v-model="configurationItem.notes" class="w-100 notes" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';

export default {
  components: {
    'p-button': Button,
    'p-dropdown': Dropdown,
    'p-input-text': InputText,
    'p-text-area': Textarea
  },
  data: function() {
    return {
      configurationItem: this._.clone(this.initialConfigurationItem),
      users: []
    }
  },
  props: {
    initialConfigurationItem: {
      type: Object,
      required: true
    },
    shouldSave: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateNotes(event) {
      this.$emit('save-success', this.configurationItem);
      axios.patch(`/configuration_items/${this.configurationItem.id}`, this.configurationItem)
          .then((res) => {
            this.$toast.add({severity:'success', summary: 'Success', detail:'Configuration item updated.', life: 3000});
            this.configurationItem = res.data.configurationItem;
            this.$emit('save-success', this.configurationItem);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Configuration item could not be updated', life: 3000});
            this.$emit('save-fail', error);
          })
    },
  },
  mounted() {
  },
  watch: {
    shouldSave: function(newShouldSave) {
      if (newShouldSave === true) {
        this.updateNotes();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-body{
    height: 200px;
  }

  .notes{
     height: 180px;
  }
</style>