<template>
  <div class="">
    <div class="row flex-row">
      <div class="column">
        <p-button label="Link Resource Group" icon="fa fa-layer-group" />
        <p-button label="Link Specific Resource" icon="fa fa-desktop" />
      </div>
    </div>
    <div class="row flex-row">
      <div class="column">
        <p-dropdown v-model="selectedAzureSubscription" :options="azureSubscriptions" optionLabel="name" optionValue="azure_resource_identifier"></p-dropdown>
        <p-autocomplete v-model="selectedResourceGroup" :suggestions="resourceGroupResults" @complete="searchResourceGroup($event)"  field="name" placeholder="Resource Group Name" >
          <template #item="slotProps">
            <span class="d-flex flex-column">
              <span class="flex-row">
                <span class="fw-bold text-primary">{{ slotProps.item.name }}</span> -
                <span class="text-secondary">{{ slotProps.item.type }}</span>
                <small class="text-secondary">({{ slotProps.item.azure_resource_identifier }})</small>
              </span>
              <span class="d-flex flex-row mt-2">
                <span class="column">
                  <span v-if="slotProps.item.subscription_configuration_items.length > 0" class="text-secondary">
                    Linked to {{ slotProps.item.subscription_configuration_items.length }} subscription(s):
                  </span>
                </span>
                <span class="d-flex flex-column ms-2">
                  <table>
                    <tr v-for="item in slotProps.item.subscription_configuration_items" class="font-monospace text-secondary small">
                      <td>{{  item.subscription.client.name }}</td>
                      <td class="ps-3">{{  item.subscription.name }}</td>
                      <td class="ps-3">{{ item.cost_ratio }}</td>
                    </tr>
                  </table>
                </span>
              </span>
            </span>


          </template>
        </p-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';

import axios from 'axios';

export default {
  components: {
    'p-button': Button,
    'p-autocomplete': AutoComplete,
    'p-dropdown': Dropdown,
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedResourceGroup: '',
      resourceGroupResults: [],
      selectedAzureSubscription: '',
      azureSubscriptions: [],
    }
  },
  methods: {
    searchzResourceGroup(event){
      this.resourceGroupResults = [];
      let newResults = []

      let existingQuery = axios.get('/configuration_items?type=ConfigurationItemTypes::AzureResourceGroup&query=' + event.query)
      let azureQuery = axios.post('/azure_search',  { query: event.query, search_target: 'ResourceContainers', enrich: true })

      Promise.all([azureQuery, existingQuery ])
        .then((values) => {
          values.map(val => {
            if (val['data']['configuration_items'] === undefined ) {
              console.log('ey')
              return null;
            }
            this.resourceGroupResults = val['data']['configuration_items']
          })
        })
    },
    searchResourceGroup(event) {
      // this.resourceGroupResults = [];
      // let newResults = []

      let existingQuery = axios.get('/configuration_items?type=ConfigurationItemTypes::AzureResourceGroup&query=' + event.query)
      let azureQuery = axios.post('/azure_search',  { query: event.query, search_target: 'ResourceContainers', enrich: true })

      Promise.all([existingQuery, azureQuery])
          .then((values) => {
              values.map(val => {
                // We need to convert the Azure search results into a format the UI can understand
                if (val['data']['configuration_items'] === undefined ) {
                  val['data'].filter(result => {
                    if (result['existing'] === true) {
                      return null;
                    }

                    let newObj = {
                      azure_resource_identifier: result['id'],
                      name: result['name'],
                      subscription_configuration_items: []
                    }
                    console.log({ pushed: newObj })
                    this.resourceGroupResults.push(newObj);
                  })
                } else {
                  val['data']['configuration_items'].map(ci => {
                    this.resourceGroupResults.push(ci)
                  })
                }
              })
            // this.resourceGroupResults = newResults;
            console.log(this.resourceGroupResults)
            })
          .catch(err => {
            console.error(err)
            this.resourceGroupResults = [];
            this.$toast.add({severity:'error', summary: 'Error', detail:'Error fetching configuration items', life: 3000});
          })

          // .then((res) => {
          //
          // })
          // .catch(err => {
          //   this.resourceGroupResults = [];
          //   this.$toast.add({severity:'error', summary: 'Error', detail:'Error fetching configuration items', life: 3000});
          // })
          //
          //
          // .then((res) => {
          //   this.resourceGroupResults = res['data']['configuration_items'];
          // })
          // .catch(err => {
          //   this.resourceGroupResults = [];
          //   this.$toast.add({severity:'error', summary: 'Error', detail:'Error search azure for CI', life: 3000});
          // })
    },
    fetchAzureSubscriptions() {
      axios.get('/configuration_items?type=ConfigurationItemTypes::AzureSubscription')
          .then((res) => {
            this.azureSubscriptions = res['data']['configuration_items'];
            this.selectedAzureSubscription = this.azureSubscriptions[0].azure_resource_identifier;
          })
          .catch(err => {
            this.azureSubscriptions = [];
            this.$toast.add({severity:'error', summary: 'Error', detail:'Error fetching Azure subscriptions', life: 3000});
          })
    },
  },
  mounted() {
    this.fetchAzureSubscriptions();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .p-datatable.p-datatable-gridlines.p-datatable-scrollable .p-datatable-tbody > tr > td + td {
    justify-content: center;
    width: 20vw;
  }

  ::v-deep .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #dbf3ff;
    color: #007bff;
}

  ::v-deep .p-datatable {
    .p-column-header-content {
      width: 100%;
      display: flex;
      padding: 0.2rem 1rem;
    }

    .p-datatable-header {
      background: transparent;
      border-width: 0px;
      padding: 1rem 0rem;
    }

    .client-column {
      width: 20vw;
      min-height: max-content;

      a {
        word-wrap: break-word
      }
    }

    .status-column {
      max-width: 3em;
      text-align: center;
    }
  }

.client-index-container {
  width: 100%;
  height: 100%;

  a {
    text-decoration: inherit;
  }

  .create-client-modal-container {
    ::v-deep div .p-dialog .p-dialog-content {
      width: 60vw;
      min-height: 62vh;
    }
  }

  .create-client-form {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 0.5em;
    }
  }

  .create-client-modal {
    width: 80%;
  }
}

.title-div{
  color: black;
  border-bottom: 1px solid #f2f3f9;
  padding-bottom: 19.5px;
}
</style>