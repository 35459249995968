<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-6">
        <h6>Name</h6>
        <p-input-text type="text" v-model="client.name" class="w-100" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-3">
        <h6>Start Date</h6>
        <p-calendar v-model="client.start_date" class="w-100" />
      </div>
      <div class="col-md-3">
        <h6>Owner</h6>
        <p-dropdown
            v-model="client.owner_id"
            :options="users"
            :filter="true"
            optionLabel="email"
            optionValue="id"
            class="w-100" />
      </div>
    </div>

<!--    <div class="row mb-4">-->
<!--      <div class="col-md-6">-->
<!--        <h6>Description</h6>-->
<!--        <p-input-text type="text" v-model="client.description" class="w-100" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from 'axios';

import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';

export default {
  components: {
    'p-dropdown': Dropdown,
    'p-input-text': InputText,
    'p-button': Button,
    'p-calendar': Calendar,
  },
  data: function() {
    return {
      client: this._.clone(this.initialClient),
      users: [],
    }
  },
  props: {
    initialClient: {
      type: Object,
      required: true
    },
    shouldSave: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    updateClient(event) {
      axios.patch(`/clients/${this.client.id}`, this.client)
          .then((res) => {
            this.$toast.add({severity:'success', summary: 'Success', detail:'Client record updated.', life: 3000});
            this.client = res.data.client;
            this.$emit('save-success', this.client);
          })
          .catch((error) => {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Client could not be updated', life: 3000});
            this.$emit('save-fail', error);
          })
    },
    getUsers() {
      axios.get('/users/')
          .then((res) => {
            this.users = res.data.users;
          })
          .catch((error) => {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not fetch users', life: 3000});
          })
    },
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    shouldSave: function(newShouldSave) {
      if (newShouldSave === true) {
        this.updateClient();
      }
    }
  }
}
</script>