<template>
  <div class="border-bottom bottom rounded">
    <div class="top row">
      <div class="col-12">
        <h2>
          <slot name="title"></slot>
        </h2>
        <h6>
          <slot name="subtitle"></slot>
        </h6>
        <span class="text-secondary">
          <slot name="metadata"></slot>
        </span>
      </div>
      <div class="mt-2 py-2">
        <slot name="extraInfo"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
