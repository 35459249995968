<template>
  <div class="my-2 d-flex align-items-center">
    <a :href="breadcrumbs[0].url"><p-button class="home-button" icon="fas fa-home"></p-button></a>
    <span v-for="(item, index) in breadcrumbs" :key="item.url">
      <a :href="item.url" :class="computeClass(index == (breadcrumbs.length-1))">
        {{item.label}}
      </a>
      <span v-if="index < (breadcrumbs.length-1)" class="p-1">/</span>
    </span>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components: {
    'p-button': Button,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    }
    // Example:
    // breadcrumbs: [
    //   {
    //     label: 'Displayed name',
    //     url: /path/to/page
    //   },
    // ]
  },
  methods: {
    computeClass(isCurrentDir) {
      if (isCurrentDir) {
        return 'current-dir-text';
      } else {
        return '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .current-dir-text {
    color: black;
  }

  a, span {
    color: grey;
    text-decoration: inherit;
  }
</style>