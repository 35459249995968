<template >
  <div class="datatable">
    <!-- Row group by client name -->
    <p-datatable
      :value="subscriptions"
      :paginator="true"
      :rows="25"
      :filters="filters"
      rowGroupMode="rowspan"
      groupRowsBy="client.name"
      filter-display="menu"
      paginatorPosition="top"
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="{first} - {last} of {totalRecords}"
      responsiveLayout="scroll"
    >
      <template #FirstPageLink>
        <p-button type="button" icon="pi pi-search" />
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <p-inputtext
            class="client-search-bar"
            v-model="filters['global'].value"
            placeholder="Start typing to search"
          ></p-inputtext>
        </span>
        <p-button
          class="add-client-button"
          label="+ Add Group"
          @click="openCreateClientModal"
        ></p-button>
      </template>
      <p-column field="client.name" sortable>
        <template #header>
          <span class="px-3">Group</span>
        </template>
        <template #body="{ data }">
            <a class="px-3" :href="`/clients/${data.client_id}`">{{data.client.name}}</a>
        </template>
      </p-column>
      <p-column field="name" header="Subscription" sortable>
        <template #body="{ data }">
            <a :href="`/clients/${data.client_id}?subscription=${data.id}`">{{data.name }}</a>
        </template>
      </p-column>
      <p-column field="status" header="Status" sortable>
        <template #body="{ data }">
          <span :class="'datatable-badge status-' + data.status">{{
            data.status
          }}</span>
        </template>
      </p-column>
      <p-column field="identifier" header="Identifier" />
      <p-column field="external_id" header="Hubspot ID" />
<!--      <p-column field="dev_code" header="Dev Code" />-->
<!--      <p-column field="description" header="Description" sortable headerStyle="width: 35%">-->
<!--        <template #body="{ data }">-->
<!--          <span>-->
<!--            {{ data.description }}-->
<!--          </span>-->
<!--        </template>-->
<!--      </p-column>-->
    </p-datatable>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row"; //optional for row
import InputText from "primevue/inputtext";
import Button from "primevue/button";

import { FilterMatchMode } from "primevue/api";

export default {
  components: {
    "p-datatable": DataTable,
    "p-column": Column,
    "p-columngroup": ColumnGroup,
    "p-row": Row,
    "p-inputtext": InputText,
    "p-button": Button,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },

  methods: {
    openCreateClientModal() {
      this.$emit("open-create-client-modal");
    },
  },

  computed: {},
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.status-inactive {
  background-color: #f9cbcb;
  color: #000000;
}

.status-pending {
  background-color: #f8edac;
  color: #000000;
}

.status-active {
  background-color: #c6e2c8;
  color: #000000;
}
</style>
