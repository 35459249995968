<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h3 class="text-primary title">CONFIGURATION ITEM INFORMATION</h3>
    <div class="overview-box">
      <overview-box :initialTags="tags">
        <template v-slot:title>
          <div class="config-item-title">
            {{ configurationItem.name }}
            <p-button @click="openEditModal">Edit</p-button>
          </div>
        </template>
        <template v-slot:subtitle>
          {{ configurationItem.type }}
        </template>
        <template v-slot:metadata>
          <span class="me-4"
            >Created at:
            <parsed-date :datetime="configurationItem.created_at"></parsed-date
          ></span>
          <span class="me-4"
            >Updated at:
            <parsed-date :datetime="configurationItem.updated_at"></parsed-date
          ></span>
        </template>
        <template v-slot:extraInfo>
          {{ configurationItem.description }}
        </template>
      </overview-box>
    </div>

    <p-dialog
      header="Edit Configuration Items"
      :visible.sync="editModalVisible"
      :style="{ width: '70vw' }"
      :modal="true"
      v-click-modal-outside="closeEditModal"
    >
      <config-items-form
        :initial-configuration-item="configurationItem"
        :should-save="shouldSave"
        @save-success="onSaveSuccess"
      ></config-items-form>

      <template #footer>
        <p-button
          label="Cancel"
          icon="pi pi-times"
          @click="closeEditModal"
          class="p-button-text"
        />
        <p-button
          label="Save"
          icon="fas fa-save"
          @click="saveEditModal"
          autofocus
        />
      </template>
    </p-dialog>

    <tag-section :configurationItem="initialConfigurationItem" />
  </div>
</template>

<script>
import overviewBox from "../../components/overviewBox";
import parsedDate from "../../components/parsedDate";
import Breadcrumbs from "../../components/styledBreadcrumb.vue";
import ConfigurationItemsForm from "./_form";
import tagSection from "../../components/tagSection.vue";

import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  components: {
    "config-items-form": ConfigurationItemsForm,
    "overview-box": overviewBox,
    "tag-section": tagSection,
    "parsed-date": parsedDate,
    "p-button": Button,
    "p-dialog": Dialog,
    breadcrumbs: Breadcrumbs,
  },
  data() {
    return {
      tags: ["vaccine"],
      configurationItem: this.initialConfigurationItem,
      editModalVisible: false,
      shouldSave: false,
      breadcrumbs: [
        {
          label: "Clients",
          url: "/clients",
        },
        {
          label: this.initialClient.name,
          url: `/clients/${this.initialClient.id}`,
        },
        {
          label: `${this.initialPod} - ${this.initialApplication.name}`,
          url: `/applications/${this.initialApplication.id}?pod=${this.initialPod}&client_name=${this.initialClient.name}&client_id=${this.initialClient.id}`,
        },
        {
          label: `${this.initialConfigurationItem.name}`,
        },
      ],
    };
  },
  props: {
    initialApplication: {
      type: Object,
      required: true,
    },
    initialClient: {
      type: Object,
      required: true,
    },
    initialPod: {
      type: String,
      required: true,
    },
    initialConfigurationItem: {
      type: Object,
      require: true,
    },
  },
  methods: {
    openEditModal: function () {
      this.editModalVisible = true;
    },
    closeEditModal: function () {
      this.editModalVisible = false;
    },
    saveEditModal: function () {
      this.shouldSave = true;
    },
    onSaveSuccess: function (event) {
      this.configurationItem = JSON.parse(JSON.stringify(event));
      (this.shouldSave = false), this.closeEditModal();
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.title {
  padding: 2rem;
}

.overview-box {
  padding: 0 2rem;
}

.config-item-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
