<template>
  <div class="d-flex flex-column mx-3">
    <div class="row">
      <div class="float-label col-md-12">
        <label>{{ contactType === 'external' ? '# of External Contacts' : 'Allowed # of Authorized Users:' }}</label>
        <p-dropdown
          class="input-display-text min-width"
          :options="numOfAuthUsersOptions"
          optionLabel="label"
          optionValue="value"
          v-model.number="numOfAuthUsers"
          type="number"
          :disabled="!editable"
        >
        </p-dropdown>
      </div>
    </div>
    <!-- TODO: Autofocusing doesn't work fix for later -->
    <div>
      <p-datatable
        :value="contactUsers"
        :editMode="canEditCell"
        @cell-edit-complete="onCellEditComplete"
        class="editable-cells-table"
        responsiveLayout="scroll"
      >
        <p-column
          field="first_name"
          header="First Name"
          :styles="{ width: '10%' }"
        >
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
        <p-column
          field="last_name"
          header="Last Name"
          :styles="{ width: '10%' }"
        >
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
        <p-column field="title" header="Title" :styles="{ width: '10%' }">
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
        <p-column field="email" header="Email" :styles="{ width: '10%' }">
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
        <p-column
          field="phone_number"
          header="Phone"
          :styles="{ width: '10%' }"
        >
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
<!--        <p-column field="tags" header="Tags" :styles="{ width: '25%' }">-->
<!--          <template #editor="slotProps">-->
<!--            <p-autocomplete-->
<!--              :multiple="true"-->
<!--              v-model="slotProps.data[slotProps.column.field]"-->
<!--              :suggestions="filteredTags"-->
<!--              @complete="searchTags($event)"-->
<!--              field="name"-->
<!--            />-->
<!--          </template>-->
<!--          <template #body="slotProps">-->
<!--            <div class="tag-display">-->
<!--              <div-->
<!--                v-for="(tag, index) in slotProps.data[slotProps.column.field]"-->
<!--                :key="index"-->
<!--              >-->
<!--                <div class="chip" :style="{ backgroundColor: '#' + tag.color }">-->
<!--                  {{ tag.name }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </p-column>-->
<!--        <p-column field="notes" header="Notes" :styles="{ width: '20%' }">-->
<!--          <template #editor="slotProps">-->
<!--            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />-->
<!--          </template>-->
<!--        </p-column>-->
        <div v-if="editable">
          <p-column field="options" header="Options" :styles="{ width: '5%' }">
            <template #body="slotProps">
              <div class="text-center">
                <i
                  class="fas fa-minus-circle"
                  v-on:click="clearRow(slotProps)"
                ></i>
              </div>
            </template>
          </p-column>
        </div>
      </p-datatable>
    </div>
    <div v-show="false" class="validation-check-box">
      <p-checkbox id="save-checkbox" inputId="binary" v-model="checked" :binary="true" />
      <label for="binary">Check before saving!</label>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tagsMixin from './mixins/tagsMixin';

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import OverlayPanel from "primevue/overlaypanel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";

export default {
  mixins: [tagsMixin],
  components: {
    "p-button": Button,
    "p-inputtext": InputText,
    "p-calendar": Calendar,
    "p-textarea": Textarea,
    "p-dropdown": Dropdown,
    "p-overlay-panel": OverlayPanel,
    "p-datatable": DataTable,
    "p-column": Column,
    "p-autocomplete": AutoComplete,
    "p-checkbox": Checkbox
  },
  data: function () {
    return {
      checked: false,
      tags: [],
      filteredCountries: null,
      users: [],
      // bug in Vue that it doesn't accept numbers as labels
      // https://github.com/primefaces/primeng/issues/9870
      numOfAuthUsersOptions: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
      ],
      numOfAuthUsers: 0,
      emptyAuthorizedUser: {},
      contactUsers: [],
      suggestedTags: [],
      parentType: "ContactPriorities",
    };
  },
  props: {
    initialSupportInformation: {
      type: Object,
    },
    subscription: {
      type: Object,
      required: true,
      default: [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    contactType: {
      type: String,
      required: true,
      default: "external",
    },
  },
  methods: {
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      data[field] = newValue;
    },
    save() {
      const checkbox = document.getElementById("save-checkbox");
      checkbox.focus();
      this.checked = true
      this.updateContactList();
    },
    updateContactList() {
      axios
        .post(`/update_contact_list`, {
          contacts: this.contactUsers,
          contactable_id: this.subscription.id,
          contactable_type: "Subscription",
          contact_type: this.contactType,
          contact_priority: "other",
          allowed_authorized_users: this.numOfAuthUsers,
        })
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Support Information record updated.",
            life: 3000,
          });
          this.getcontactUsers(res.data.contact_priorities);
          this.$emit("save-success", res.data.contact_priorities);
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Support Information could not be updated",
            life: 3000,
          });
          this.$emit("save-fail", error);
        });
    },
    reset() {
      this.$emit("close");
    },
    parseDate(date) {
      return new Date(date).toLocaleTimeString();
    },
    displayValue(value) {
      return value === null || value === undefined ? "N/A" : value;
    },
    clearRow(index) {
      this.contactUsers[index["index"]] = this._.clone({
        ...this.emptyAuthorizedUser,
        deleted: this.contactUsers[index["index"]].contact_priority_id,
      });
      this.$forceUpdate();
    },
    getcontactUsers(contact_priorities) {
      this.contactUsers = contact_priorities
        .filter(
          (contact_priority) =>
            contact_priority.contact.contact_type == this.contactType
        )
        .map((contact_priority) => ({
          contact_type: contact_priority.contact.contact_type,
          contact_id: contact_priority.contact_id,
          first_name: contact_priority.contact.first_name,
          last_name: contact_priority.contact.last_name,
          email: contact_priority.contact.email,
          title: contact_priority.contact.title,
          phone_number: contact_priority.contact.phone_number,
          notes: contact_priority.notes,
          contact_priority_id: contact_priority.id,
          tags: contact_priority.model_tags?.map((tag) => tag.tag_template),
        }));
    },
  },
  created() {
    this.emptyAuthorizedUser = {
      contact_type: this.contactType,
      email: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      title: null,
      notes: null,
    };
  },
  watch: {
    numOfAuthUsers: function () {
      let currNumOfAuthUsers = this.contactUsers.length;
      if (this.numOfAuthUsers > currNumOfAuthUsers) {
        while (this.numOfAuthUsers > currNumOfAuthUsers) {
          this.contactUsers.push(this._.clone(this.emptyAuthorizedUser));
          currNumOfAuthUsers++;
        }
        this.numOfAuthUsers = currNumOfAuthUsers;
      } else {
        this.contactUsers = this.contactUsers.slice(0, this.numOfAuthUsers);
      }
      this.$forceUpdate();
    },
    'subscription.contact_priorities': function() {
      this.getcontactUsers(this.subscription.contact_priorities);
    }
  },
  mounted() {
    this.getcontactUsers(this.subscription.contact_priorities);
    this.numOfAuthUsers =
      this.contactType == "zendesk"
        ? this.initialSupportInformation["allowed_authorized_users"]
        : this.subscription["allowed_contacts"];
    this.getSuggestedTags();
  },
  computed: {
    canEditCell() {
      if (!this.editable) {
        return "row";
      }
      return "cell";
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-display {
  display: flex;
  align-items: center;
}
.auth-user-row {
  div {
    padding: 0 1rem 0 0;
  }

  .p-button {
    background-color: var(--red-200);
    border-color: var(--red-200);
  }
}

.fa-minus-circle {
  color: #8c0101;
  cursor: pointer;
}
</style>