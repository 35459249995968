<template>
  <div>
    <Toast position="bottom-left" />
    <ConfirmDialog />
  </div>
</template>
<script>
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  components: {
    'Toast': Toast,
    'ConfirmDialog': ConfirmDialog
  }
}
</script>