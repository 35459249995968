<template>
  <div>
    <h3 class="text-primary">Azure Search</h3>

    <p-input v-model="query" v-on:keyup.enter="searchAzure" class="w-50" placeholder="Search term..."></p-input>
    <p-multiselect v-model="typeFilter" :options="types" optionLabel="label" optionValue="value"></p-multiselect>
    <p-button label="Search" @click="searchAzure"></p-button>

    <p-datatable class="mt-4" :value="results" responsiveLayout="scroll" :globalFilterFields="['name', 'type']">
      <p-column field="type" header="Code" sortable></p-column>
      <p-column field="name" header="Name" sortable></p-column>
    </p-datatable>
  </div>
</template>

<script>
import axios from 'axios';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';

export default {
  components: {
    'p-button': Button,
    'p-input': InputText,
    'p-datatable': DataTable,
    'p-column': Column,
    'p-multiselect': MultiSelect
  },
  data() {
    return {
      results: [],
      query: "",
      types: [
        { label: 'Kubernetes Cluster', value: 'microsoft.containerservice/managedclusters' },
        { label: 'PostgreSQL Server', value: 'microsoft.dbforpostgresql/servers' },
        { label: 'Virtual Machine', value: 'microsoft.compute/virtualmachines' }
      ],
      typeFilter: '',
    }
  },
  methods: {
    searchAzure() {
      axios.post('/azure_search/', { query: this.query, filter: this.typeFilter })
          .then((res) => {
            this.results = res.data;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not make query', life: 3000});
          })
    }
  },
  mounted() {
    this.typeFilter = this.types.map(x => x['value'])
  }
}
</script>