<template>
  <div class="show-ci">
    <overview-box :initialTags="tags">
      <template v-slot:title>
        Application Information
      </template>

      <template v-slot:editButton>
        <span @click="openEditModal">Edit</span>
      </template>

      <template v-slot:subtitle>
      </template>

      <template v-slot:metadata>
        <span class="me-4"><strong>Created at:</strong> <parsed-date :datetime="application.created_at"></parsed-date></span>
        <span class="me-4"><strong>Updated at:</strong> <parsed-date :datetime="application.updated_at"></parsed-date></span>
      </template>

      <template v-slot:contact>
      </template>

      <template v-slot:extraInfo>
        {{ application.description }}
      </template>

    </overview-box>

    <div class="d-flex flex-row justify-content-between align-items-center my-4">
      <h5 class="text-primary">CONFIGURATION ITEMS</h5>
      <p-button label="Add New CI" @click="openNewCIModal"></p-button>
    </div>

    <div
      v-for="(configuration_item, index) in application.configuration_items"
      :key="configuration_item.id"
      class="card p-3 my-2 d-flex flex-row justify-content-between align-items-center"
    >
      <a
      :href="`/configuration_items/${configuration_item.id}?application_name=${application.name}&application_id=${application.id}&pod=${pod}&client_name=${client.name}&client_id=${client.id}`"
    >
        <strong> {{ configuration_item.type }}:<br /> </strong>
        {{ configuration_item.name }}
      </a>
      <p-button class="trash-button" icon="pi pi-trash" @click="deleteConfigurationitem(configuration_item.id)"></p-button>
    </div>

    <p-dialog
      header="Edit Application"
      :visible.sync="editModalVisible"
      :style="{width: '70vw'}"
      :modal="true"
      v-click-modal-outside="closeEditModal"
    >
      <application-form
        :initial-application="application"
        :should-save="shouldSave"
        @save-success="onSaveSuccess"
      ></application-form>

      <template #footer>
        <p-button label="Cancel" icon="pi pi-times" @click="closeEditModal" class="p-button-text"/>
        <p-button label="Save" icon="fas fa-save" @click="saveEditModal" autofocus />
      </template>
    </p-dialog>

    <p-dialog
        header="Add Configuration Item"
        :visible.sync="ciModalVisible"
        :style="{width: '70vw'}"
        :modal="true"
        v-click-modal-outside="closeNewCIModal"
        class="faded-bg"
        position="top"
    >
      <new-ci-form :application_id="application.id" @wizard-complete="newConfigurationItemAdded"></new-ci-form>
    </p-dialog>
  </div>
</template>

<script>
import axios from "axios";

import overviewBox from "../../components/overviewBox";
import parsedDate from "../../components/parsedDate";
import Breadcrumbs from "../../components/styledBreadcrumb.vue";
import newCiForm from "./newCi/_newCiForm";

import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  components: {
    "overview-box": overviewBox,
    "parsed-date": parsedDate,
    "new-ci-form": newCiForm,
    "p-button": Button,
    "p-dialog": Dialog,
    breadcrumbs: Breadcrumbs,
  },
  data() {
    return {
      tags: [],
      ciModalVisible: false,
      editModalVisible: false,
      shouldSave: false,
      application: this._.clone(this.initialApplication),
      client: this.initialClient,
      pod: this.initialPod,
      breadcrumbs: [
        {
          label: "Clients",
          url: "/clients",
        },
        {
          label: this.initialClient.name,
          url: `/clients/${this.initialClient.id}`,
        },
        {
          label: `${this.initialPod} - ${this.initialApplication.name}`,
        },
      ],
    };
  },
  props: {
    initialApplication: {
      type: Object,
      required: true,
    },
    initialClient: {
      type: Object,
      required: true,
    },
    initialPod: {
      type: String,
      required: true,
    },
  },
  methods: {
    openEditModal: function() {
      this.editModalVisible = true;
    },
    closeEditModal: function() {
      this.editModalVisible = false;
    },
    saveEditModal: function() {
      this.shouldSave = true;
    },
    onSaveSuccess: function(event) {
      this.application = JSON.parse(JSON.stringify(event));
      this.closeEditModal();
    },
    openNewCIModal: function () {
      this.ciModalVisible = true;
    },
    closeNewCIModal: function () {
      this.ciModalVisible = false;
    },
    getApplication: function () {
      axios
        .get(`/applications/${this.application.id}`)
        .then((res) => {
          this.application = res.data.application;
        })
        .catch((error) => {
          this.$toast.add({severity:'error', summary: 'Error', detail:'Could not update application', life: 3000});
        })
    },
    newConfigurationItemAdded: function () {
      this.closeNewCIModal();
      this.getApplication();
    },
    deleteConfigurationitem(configuration_item_id) {
      axios.delete(`/configuration_items/${configuration_item_id}`)
      .then((res) => {
        this.getApplication();
        this.$toast.add({severity:'success', summary: 'Success', detail:'configuration item deleted.', life: 3000});
      })
      .catch((error) => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'configuration item could not be deleted', life: 3000});
      })
    }
  },
  mounted() {
    this.application = this.initialApplication;
  }
}
</script>

<style lang="scss" scoped>
  a {
    color: inherit;
    text-decoration: inherit;
  }

  .trash-button {
    color: inherit;
    background-color: inherit;
    border: 0px;
  }
</style>
