<template>
  <div>
    <div v-for="contact in contacts" :key="contact.id" class="mb-4 m-2 w-25">
      <div class="d-flex p-0 flex-row align-items-center border rounded box-width">
        <div :class="computeContactBoxClass(contact)">
          <span v-if="contact.email.includes('@verto.ca')" class="d-flex align-items-center p-2">{{ capitalize(contact.contact_priority) }}:</span>
          <span v-else class="subheaders">
            <span>Name:</span>
            <span v-if="contact.title && contact.title.length > 0">Title:</span>
            <span v-if="contact.phone_number && contact.phone_number.length > 0">Phone:</span>
            <span v-if="contact.email && contact.email.length > 0">Email:</span>
          </span>
        </div>
        <div class="p-2 d-flex flex-column">
          <span class="text-truncate">{{ contact.first_name }} {{contact.last_name}}</span>
          <span class="d-flex flex-column" v-if="!contact.email.includes('@verto.ca')">
            <span class="text-truncate" v-if="contact.title &&  contact.title.length > 0">{{ contact.title }}</span>
            <span class="text-truncate" v-if="contact.phone_number && contact.phone_number.length > 0">{{ contact.phone_number }}</span>
            <span class="text-truncate" v-if="contact.email && contact.email.length > 0">{{ contact.email }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    contacts: {
      type: Array,
      require: true
    }
  },
  methods: {
    computeContactBoxClass(contact) {
      let styleClass = '';
      if (contact.email.includes('@verto.ca')) {
        styleClass += 'verto-contact';
      } else {
        styleClass += 'other-contact';
      }
      if (contact.contact_priority === 'other') {
        styleClass += ' other-contact-color';
      } else {
        styleClass += ' primary-contact-color';
      }
      return styleClass;
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>

<style lang="scss" scoped>
  .other-contact {
    height: 100%;
    width: min-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .other-contact-color {
    background-color: skyblue;
  }

  .primary-contact-color {
    background-color: lavenderblush;
  }

  .verto-contact {
    height: 40px;
    
    display: flex;
    justify-content: center;

    .card-body {
      height: min-content;
    }
  }

  .box-width {
    min-width: 20rem;
  }
</style>