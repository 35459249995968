import Vue from 'vue/dist/vue.esm.js';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import axios from 'axios';

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Import PrimeVue CSS
import primevueTheme from 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import primevueCss from 'primevue/resources/primevue.min.css';
import primevueIcons from 'primeicons/primeicons.css';
import primvevueConfig from 'primevue/config';

Vue.use(primvevueConfig)

// Import utility components
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import Clipboard from 'v-clipboard'
import ConfirmationService from 'primevue/confirmationservice';

Vue.use(ToastService);
Vue.use(Clipboard)
Vue.use(ConfirmationService);

// Import generic components if required
import toastSource from '../components/toast'

// Load all components from views and export all components
import comps from '../views/views'
const components = {
  'toast-source': toastSource,
  ...comps
}

// Debugger to see all available loaded components. Components are named in the format of
//      `${controller}__${action}`
if (process.env.RAILS_ENV === 'development') {
  console.log({loaded_components: components})
}

// Document and application initialization
document.addEventListener('DOMContentLoaded', () => {
  // Axios configuration
  axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
  axios.defaults.headers.common['Accept'] = 'application/json';

  // Lodash config so we don't need to include it everywhere
  Vue.use(VueLodash, { lodash: lodash });

  // Load Vue directives
  loadVueDirectives();

  // Dynamically initialize any Vue elements on the page
  document.querySelectorAll('[data-source="vue"]').forEach(element => {
    const app = new Vue({
      el: `[data-source="vue"][data-type="${element.dataset.type}"]`,
      components: components
    })
  })
})


const loadVueDirectives = () => {
  // Add a directive so we can use `v-click-outside="function"`
  Vue.directive('click-outside', {
    bind(el, binding, vnode) {
      var vm = vnode.context;
      var callback = binding.value;

      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          return callback.call(vm, event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  });

  // Add a directive so we can use `v-click-modal-outside="function"`
  Vue.directive('click-modal-outside', {
    bind(el, binding, vnode) {
      var vm = vnode.context;
      var callback = binding.value;

      el.clickModalOutsideEvent = function (event) {
        // Invoke callback when the component clicking is the mask of the dialog
        var elementArray = ['p-dialog-mask', 'p-component-overlay', 'p-component-overlay-enter']
        if (elementArray.every(v => event.target.classList.contains(v))) {
          return callback.call(vm, event);
        }
      };
      document.body.addEventListener('click', el.clickModalOutsideEvent);
    },
    unbind(el) {
      document.body.removeEventListener('click', el.clickModalOutsideEvent);
    },
  });

  Vue.directive('tooltip', Tooltip);
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
