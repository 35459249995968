<template>
  <div class="tag-modal-container">
    <p-dialog
      :visible="modalVisible"
      :modal="true"
      :baseZIndex="1000"
      :dismissableMask="true"
      :responsive="true"
      :closable="false"
      v-on:show="onModalShow"
      v-on:hide="onModalHide"
    >
      <template #header>
        <div class="float-left modal-title">
          <h3 v-if="showAddTag"><i aria-hidden="true"></i> Add New Tag</h3>
          <h3 v-else>
            <i aria-hidden="true"></i> Edit Tag
          </h3>
        </div>
      </template>

      <div class="tag-modal-content">
        <div class="p-fluid">
          <div class="p-field">
            <label for="add-tag-name">Tag Name:</label>
            <p-inputtext
              id="add-tag-name"
              v-model="params['name']"
              placeholder="Insert a Tag Name"
              :class="{ invalid: !params['name'] }"
            />
          </div>
          <div class="p-field">
            <label for="add-tag-type">Type:</label>
            <p-dropdown
              id="add-tag-type"
              v-model="params['target_type']"
              :class="{ invalid: !params['target_type'] }"
              :options="targetTypes"
              optionValue="value"
              optionLabel="name"
              placeholder="Select a Tag Type"
            />
          </div>
          <div class="p-field color-field">
            <div class="color-header">
              <label for="add-color" class="color-label">Tag Color:</label>
              <div
                :class="{ 'invalid-color': !params['color'] }"
                class="color-preview"
                :style="'background-color:#' + params['color'] + ';'"
              ></div>
            </div>
            <p-colorpicker
              id="add-color"
              v-model="params['color']"
              :inline="true"
            />
          </div>
        </div>
      </div>

      <template #footer>
        <div>
          <p-button
            label="Cancel"
            class="cancel-btn"
            v-on:click="cancelSaveTag"
          />
          <p-button
            label="Save"
            class="save-btn"
            v-on:click="saveTag"
          />
        </div>
      </template>
    </p-dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import ColorPicker from "primevue/colorpicker";

export default {
  components: {
    "p-dialog": Dialog,
    "p-button": Button,
    "p-inputnumber": InputNumber,
    "p-inputtext": InputText,
    "p-dropdown": Dropdown,
    "p-calendar": Calendar,
    "p-colorpicker": ColorPicker,
  },
  props: {
    modalVisible: {
      required: true,
      type: Boolean,
    },
    showAddTag: {
      type: Boolean,
      default: false,
    },
    showEditTag: {
      type: Boolean,
      default: false,
    },
    selectedTag: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filters: {},
      params: {},
      targetTypes: [
        { name: "Client", value: "Client" },
        { name: "ConfigurationItem", value: "ConfigurationItem" },
        { name: "ApplicationGroup", value: "ApplicationGroup" },
        { name: "Subscription", value: "Subscription" },
        { name: "Persona", value: "ContactPriorities" },
        { name: "Document", value: "Document" },

      ],
      statusTypes: [
        { name: "Active", value: false },
        { name: "Inactive", value: true },
      ],
      tagName: null,
    };
  },
  methods: {
    onModalShow() {
      if (this.showEditTag) {
        this.params = { ...this.selectedTag };
      }
    },
    onModalHide() {},
    clearFilters() {
      this.filters = {};
      this.$emit("set-tag-filter", this.filters);
    },
    saveTag() {
      if (
        this.params["color"] &&
        this.params["name"] &&
        this.params["target_type"]
      ) {
        this.showAddTag
          ? this.$emit("save-add-tag", this.params)
          : this.$emit("save-edit-tag", this.params);
      }
    },
    cancelSaveTag() {
      this.params = {};
      this.selectedTag = null;
      this.$emit("tag-modal-close", false);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.tag-modal-container {
  .p-field {
    margin-top: 10px;
  }
  .invalid {
    border: 1px solid red;
    border-radius: 3px;
  }
  .color-field {
    display: flex;
    flex-direction: column;
  }
  .color-label {
    margin-right: 5px;
  }
  .color-header {
    display: flex;
    margin-top: 25px;
    padding-bottom: 4px;
  }
  .color-preview {
    height: 20px;
    width: 90px;
    border-radius: 10px;
    border: 1px solid #c8c8c8;
  }
  .invalid-color {
    border: 1px solid red;
  }
  .p-button-plain {
    color: #000;
    background-color: #fff;
  }
  .header-icon {
    margin-left: 10px;
    margin-right: 5px;
    color: #0d3ef0;
  }

  ::v-deep {
    .p-dialog {
      height: auto;
      width: 30%;
    }
    .p-dialog-mask {
      overflow: scroll;
    }
    .p-dialog-content {
      height: 30vw;
      border: none;
      padding: 25px 30px;
    }

    .p-dialog-titlebar {
      position: relative;
      background-color: white;
      height: 75px;
      padding: 0 2rem;
      border: none;
      border-bottom: 1px solid #c8c8c8;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      h3 {
        font-weight: 600;
        font-size: 25px;
        margin-top: 28px;
      }
    }

    .p-dialog-footer {
      border: none;
      border-top: 1px solid #c8c8c8;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
</style>
