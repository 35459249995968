<template>
  <p-dialog
    header="Edit Subscription"
    :visible.sync="displayModal"
    :containerStyle="{ width: '50vw' }"
    :modal="true"
    :closable="false"
  >
    <div class="row mb-4">
      <div class="col-md-12">
        <h6>Name:</h6>
        <p-input-text v-model="subscription.name"> </p-input-text>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <h6>Status:</h6>
        <p-dropdown
          v-model="subscription.status"
          :options="initialStatuses"
          optionLabel="label"
          optionValue="value"
          class="w-100"
        ></p-dropdown>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-12">
        <h6>Description:</h6>
      <p-textarea
        v-model="subscription.description"
        class="w-100"
        type="text"
        :autoResize="true"
        rows="3"
        cols="25"
      >
      </p-textarea>
    </div>
    </div>
    <template #footer>
      <p-button
        label="Cancel"
        icon="pi pi-times"
        @click="closeModal"
        class="p-button-text"
      />
      <p-button label="Update" icon="pi pi-check" @click="onSave" autofocus />
    </template>
  </p-dialog>
</template>


  <script>
import axios from "axios";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog/Dialog";
import Dropdown from "primevue/dropdown/Dropdown";

export default {
  components: {
    "p-button": Button,
    "p-input-text": InputText,
    "p-textarea": Textarea,
    "p-dialog": Dialog,
    'p-dropdown': Dropdown,
    
  },
  data: function () {
    return {
      description: null,
      displayModal: false,
      subscription: { ...this.initialSubscription },
      initialStatuses: [
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'pending'},
        { label: 'Inactive', value: 'inactive' },
      ],
    };
  },
  props: {
    initialSubscription: {
      type: Object,
      require: true,
      default: null,
    },
    subscriptionModal: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  methods: {
    onSave() {
      axios
        .patch(
          `/subscriptions/${this.initialSubscription.id}`,
          this.subscription
        )
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Updated subscription.",
            life: 3000,
          });
          this.subscription.description = res.data.subscription.description;
          this.subscription.name = res.data.subscription.name
          this.subscription.status = res.data.subscription.status;
          this.$emit("save-success", this.subscription);
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Subscription could not be updated",
            life: 3000,
          });
        })
        .finally(() => {
          this.closeModal();
        });
    },

    closeModal() {
      this.$emit("close-subscription-modal");
    },
  },
  watch: {
    subscriptionModal: function () {
      this.displayModal = this.subscriptionModal;
    },
  },
  mounted() {
    console.log("init_subscription", this.initialSubscription);
  },
};
</script>