<template>
  <div class="m-4">
    <div v-if="editable">
      <div class="row mb-4">
        <div class="col-md-6"><h6>New additional material name:</h6></div>
        <div class="col-md-6"><h6>New additional material link:</h6></div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6"><p-input-text class="w-100" type="text" v-model="newAdditionalMaterials['label']"/></div>
        <div class="col-md-6"><p-input-text class="w-100" type="text" v-model="newAdditionalMaterials['url']"/></div>
      </div>
      <div class="row mb-4 d-flex flex-row-reverse px-3">
        <p-button class="col-md-1" label="Add" @click="addAdditionalMaterial"></p-button>
      </div>
      <h6>Current additional materials:</h6>
      <div class="d-flex flex-column flex-wrap">
        <div v-for="(value, key) in this.additionalMaterials" :key="key" class="d-flex justify-content-between align-items-center">
          <span class="w-100">{{ key }}: <a :href="value">{{ value }}</a></span>
          <p-button class="delete-icon" icon="pi pi-times" @click="removeAdditionalMaterial(key)"></p-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-wrap">
        <div v-for="(value, key) in this.additionalMaterials" :key="key">
          <a :href="value.replace(/^www.?/, 'https://')" target="_blank">
            {{key}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {
    'p-button': Button,
    'p-input-text': InputText,
  },
  props: {
    initialSubscription: {
      type: Object,
      require: true
    },
    editable: {
      type: Boolean,
      require: true,
    }
  },
  data() {
    return {
      subscription: this._.clone(this.initialSubscription),
      additionalMaterials: this._.clone(this.initialSubscription.additional_materials) || {},
      newAdditionalMaterials: {
        label: "",
        url: "",
      },
    }
  },
  methods: {
    addAdditionalMaterial() {
      this.additionalMaterials[this.newAdditionalMaterials['label']] = this.newAdditionalMaterials['url'];
      this.newAdditionalMaterials = {
        label: "",
        url: "",
      }
    },
    removeAdditionalMaterial(materialLabel) {
      delete this.additionalMaterials[materialLabel]
      this.$forceUpdate();
    },
    save() {
      this.subscription.additional_materials = this.additionalMaterials;
      axios.patch(`/subscriptions/${this.initialSubscription.id}`, this.subscription)
      .then((res) => {
        this.$toast.add({severity:'success', summary: 'Success', detail:'Updated subscription.', life: 3000});
        this.additionalMaterials = res.data.subscription.additional_materials;
        this.$emit('save-success', this.additionalMaterials)
      })
      .catch((error) => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Subscription could not be updated', life: 3000});
      })
    },
    reset() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .delete-icon {
    &.p-button {
      background-color: inherit;
      color: black;
      border: 0px;
    }
  }
</style>