<template>
  <span>
    <p-tag rounded class="me-3">
      {{ contact.first_name }} {{ contact.last_name }} · {{ contact.email }} · {{ contact.phone_number }}
      <i class="pi pi-pencil ms-2" @click="openEditModal"></i>
    </p-tag>

    <p-dialog
      header="Edit Contacts"
      :visible.sync="editModalVisible"
      :style="{width: '50vw'}"
      :modal="true"
      v-click-modal-outside="closeEditModal"
    >
      <contact-form v-on="$listeners"
        :initial-contact="contact"
        :should-save="shouldSave"
        :should-delete="shouldDelete"
        @delete-success="onDeleteSuccess"
        @save-success="onSaveSuccess"
      ></contact-form>

      <template #footer>
        <p-button label="Delete" @click="deleteContact" class="p-button-danger float-left" />
        <p-button label="Cancel" icon="pi pi-times" @click="closeEditModal" class="p-button-text"/>
        <p-button label="Save" icon="fas fa-save" @click="saveEditModal" autofocus />
      </template>
    </p-dialog>    
  </span>
</template>

<script>
import ContactForm from "./_form";

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Tag from 'primevue/tag';

export default {
  components: {
    'contact-form': ContactForm,
    'p-button': Button,
    'p-dialog': Dialog,
    'p-tag': Tag
  },
  data() {
    return {
      contact: this.currentContact,
      editModalVisible: false,
      shouldSave: false,
      shouldDelete: false
    }
  },
  props: {
    currentContact: {
      type: Object,
      required: false
    }
  },
  methods: {
    openEditModal: function() {
      this.editModalVisible = true;
    },
    closeEditModal: function() {
      this.editModalVisible = false;
    },
    saveEditModal: function() {
      this.shouldSave = true;
    },
    onSaveSuccess: function(event) {
      this.contact = JSON.parse(JSON.stringify(event));
      this.closeEditModal();
      this.shouldSave = false;
    },
    onDeleteSuccess: function(event) {
      this.$emit('remove-contact', event)
      this.closeEditModal();
    },
    deleteContact: function() {
      if (confirm('Please confirm deletion')) {
        this.shouldDelete = true;
      }
    }
  }
}


</script>

<style scoped>
  /* Left align delete button */
  .p-button-danger {
    position: absolute;
    left: 1rem;
  }
</style>