<template>
  <div>
    <p-card>
      <template #title>
        Find Azure Resource
      </template>
      <template #content>

        <div class="d-flex">
          <span class="p-input-icon-right me-2" style="flex: 3" >
            <i v-if="loading" class="pi pi-spin pi-spinner"/>
            <p-input v-model="query" v-on:keyup.enter="searchAzure" placeholder="Search term..." class="w-100"></p-input>
          </span>
          <p-multiselect v-model="typeFilter" class="me-2" style="flex: 2" :options="types" optionLabel="label" optionValue="value"></p-multiselect>
          <p-button label="Search" @click="searchAzure"></p-button>
        </div>


        <p-datatable :expander="true" class="mt-4" :value="results" responsiveLayout="scroll" :globalFilterFields="['name', 'type']">
          <p-column field="type" header="Code" sortable></p-column>
          <p-column field="name" header="Name" sortable></p-column>
          <p-column field="existing" header="Existing" sortable>
            <template #body="slotProps">
              <div class="text-center">
                <span v-if="slotProps.data.existing" class="fas fa-check-circle text-success"></span>
                <span v-else class="fas fa-times-circle text-warning"></span>
              </div>
            </template>
          </p-column>
          <p-column field="resourceGroup" header="Resource Group" sortable></p-column>
          <p-column>
            <template #body="slotProps">
              <p-button @click="selectResource(slotProps.data)" type="button" label="Select" icon="far fa-arrow-alt-circle-right"></p-button>
            </template>
          </p-column>
        </p-datatable>

        <div class="mt-2">
          <p-button label="Back" icon="far fa-arrow-alt-circle-left" @click="handleTransition('back')" class="p-button-text"/>
        </div>
      </template>
    </p-card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import axios from "axios";

export default {
  components: {
    'p-card': Card,
    'p-button': Button,
    'p-input': InputText,
    'p-datatable': DataTable,
    'p-column': Column,
    'p-multiselect': MultiSelect
  },
  data: function() {
    return {
      configurationItem: {},
      results: [],
      loading: false,
      query: "",
      types: [],
      typeFilter: '',
      azureCiTypes: []
    }
  },
  props: {
    initialConfigurationItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleTransition: function(type) {
      this.$emit('transition', type)
    },
    emitModel: function() {
      this.$emit('update-model', this.configurationItem)
    },
    searchAzure() {
      this.loading = true;
      axios.post('/azure_search/', { query: this.query, filter: this.typeFilter })
          .then((res) => {
            this.results = res.data;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not make query', life: 3000});
          })
          .finally(() => this.loading = false)
    },
    selectResource(resource) {
      this.configurationItem.azure_resource_identifier = resource.id;
      this.configurationItem.name = resource.name;
      this.configurationItem.type = this.azureCiTypes.filter(type => type['azure_metadata']['type'] === resource.type)[0]['class_name']
      this.emitModel();
      this.handleTransition('reviewAzureResource');
    },
    getAzureTypes() {
      axios.get('/configuration_items/azure_configuration_items')
        .then((res) => {
          this.azureCiTypes = res.data.azure_ci_types;
          this.types = this.azureCiTypes.map(type => {
            return {
              label: type['azure_metadata']['name'], value: type['azure_metadata']['type']
            }
          })
          this.typeFilter = this.types.map(x => x['value'])
        })
        .catch((error) => {
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not get Azure types', life: 3000});
        })
    }
  },
  mounted() {
    this.getAzureTypes();
    this.configurationItem = this.initialConfigurationItem;
    this.configurationItem.selectedProvider = 'azureResource';
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .p-multiselect {
    min-width: 17rem;
  }
  .p-multiselect-label {
    display: flex;
  }
  .p-multiselect-token {
    margin: 0.1rem;
  }
}

::v-deep .p-card .p-card-content {
  padding-bottom: 0;
}
</style>