<template>
  <div class="labels-list">
    <template v-if="loading || (results && results.length > 0)">
      <div class="loading" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
        </div>
      </div>
      <div v-else>
        <p-datatable :value="results" id="tag-datatable">
          <p-column
            field="name"
            header="Tag Name"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="text-center">
                {{ slotProps.data.name }}
              </div>
            </template>
          </p-column>
          <p-column
            field="color"
            header="Color"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="color-tag-cell">
                <div
                  class="color-tag"
                  :style="'background-color:#' + slotProps.data.color + ';'"
                ></div>
              </div>
            </template>
          </p-column>
          <p-column
            field="target_type"
            header="Tag Type"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="text-center">
                {{ slotProps.data.target_type }}
              </div>
            </template>
          </p-column>
          <p-column
            field="created_at"
            header="Date Added"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="text-center">
                {{ formatDate(slotProps.data.created_at) }}
              </div>
            </template>
          </p-column>
          <p-column
            field="status"
            header="Status"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="color-tag-cell text-center">
                <div v-if="slotProps.data.deleted" class="status-box inactive">
                  Inactive
                </div>
                <div v-else class="status-box active">Active</div>
              </div>
            </template>
          </p-column>
          <p-column
            field="options"
            header="Options"
            headerStyle="text-align:center;"
          >
            <template #body="slotProps">
              <div class="text-center">
                <i class="fas fa-edit" v-on:click="editTag(slotProps)"></i>
                <i
                  v-if="slotProps.data.deleted"
                  class="fas fa-plus-circle"
                  v-on:click="activateTag(slotProps)"
                ></i>
                <i
                  v-else
                  class="fas fa-minus-circle"
                  v-on:click="deleteTag(slotProps)"
                ></i>
              </div>
            </template>
          </p-column>
        </p-datatable>
      </div>
      <p-paginator
        id="tag-paginator"
        v-if="total > 0"
        :rows="pageSize"
        :totalRecords="total"
        @page="onPageChange($event)"
        :first="pageOffset"
      >
      </p-paginator>
    </template>
    <template v-else>
      <div class="not-found-result">
        <span class="item-empty">No tags found.</span>
      </div>
    </template>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import moment from "moment";
export default {
  components: {
    "p-paginator": Paginator,
    "p-datatable": DataTable,
    "p-column": Column,
  },
  props: {
    total: {
      type: Number,
    },
    pageOffset: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    results: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedPatient: null,
    };
  },
  methods: {
    onPageChange(event) {
      this.$emit("tag-page-change", event);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    deleteTag(event) {
      this.$emit("delete-tag", event.data);
    },
    activateTag(event) {
      this.$emit("activate-tag", event.data);
    },
    editTag(event) {
      this.$emit("edit-tag", event.data);
    },
  },
};
</script>

<style lang="scss">
#tag-datatable {
  .fa-minus-circle {
    color: #8c0101;
    cursor: pointer;
  }
  .fa-plus-circle {
    color: #022c17;
    cursor: pointer;
  }
  .fa-edit {
    color: #007ad9;
    cursor: pointer;
    margin-right: 15px;
  }
  .color-tag-cell {
    display: flex;
    justify-content: center;
  }
  .color-tag {
    height: 20px;
    width: 90px;
    border-radius: 10px;
  }
  .p-datatable-wrapper .p-datatable-tbody tr:nth-last-child(3) {
    border: none;
  }
  .p-datatable-wrapper .p-datatable-tbody > tr:nth-last-of-type(2) {
    border: none;
  }
  .status-box {
    height: 29px;
    width: 90px;
    border-radius: 5px;
    padding-top: 4px;
  }
  .active {
    background-color: #d4edda;
    color: #022c17;
  }
  .inactive {
    background-color: #ffe6e6;
    color: #8c0101;
  }
}

#tag-paginator {
  background-color: #f5f7fa;
  border: none;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.not-found-result {
  text-align: center;
}
.p-progress-spinner {
  width: 36px;
}
</style>
