<template>
  <div class="d-flex flex-column">
    <div v-if="authorizedUsers.length > 0">
      <a class="p-2" :href="`https://vertoinc.zendesk.com/agent/organizations/${organization['id']}/tickets`" target="_blank">
        Link to Zendesk Organization
      </a>
    </div>
    <div v-else>
      No Zendesk Organization was found with this identifier ( {{ subscription.identifier }} ).
    </div>
    <p-datatable
        :value="authorizedUsers"
        class="editable-cells-table w-100"
        responsiveLayout="scroll"
    >
      <p-column field="name" header="Name"></p-column>
      <p-column field="email" header="Email"></p-column>
    </p-datatable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import axios from 'axios';

export default {
  components: {
    'p-datatable': DataTable,
    'p-column': Column,
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      authorizedUsers: [],
      organization: {}
    }
  },
  methods: {
    fetchUsers() {
      axios.get(
          '/configuration_items?type=ConfigurationItemTypes::ZendeskOrganization&' +
          'include_resource=true&' +
          'subscription_id=' + this.subscription.id)
          .then((res) => {
            if (res.data.configuration_items.length > 0) {
              this.organization = res.data.configuration_items[0].resource.organization;
              this.authorizedUsers = res.data.configuration_items[0].resource.users;
            }
          })
          .catch(err => {
            console.error(err)
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Error fetching authorized users'});
          })
    },
  },
  mounted() {
    this.fetchUsers();
  },
}

</script>