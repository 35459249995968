<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div class="title-div mb-4">
      <h3 class="title">Versions</h3>
    </div>
    <div v-for="version in versions">
      <b>Action</b>: {{ version.event }}<br>
      <b>User</b>: {{ version.email }}<br>
      <b>Time</b>: <parsed-date :datetime="version.created_at"/><br>
      <b>Change ID</b>: {{ version.id }}<br>
      <b>Object</b>: {{ version.item_type }} / {{ version.item_id }}<br>
      <b>Changes</b>: <pre class="text-muted">{{ version.object_changes }}</pre>
      <hr>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/styledBreadcrumb.vue";
import parsedDate from "../../components/parsedDate";

export default {
  components: {
    'breadcrumbs': Breadcrumbs,
    "parsed-date": parsedDate,
  },
  props: {
    versions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Versions",
          url: "/versions",
        },
      ],
    }
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.report-icon {
  font-size: 80px;
}
</style>