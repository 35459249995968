<template>

  <div class="client-index-container">
        <breadcrumbs
      :breadcrumbs="breadcrumbs"
    ></breadcrumbs>
    <div class="title-div d-flex flex-row justify-content-between mb-2">
      <h3 class="title">All Groups</h3>
    </div>
    <div class="create-client-modal-container">
      <p-dialog
        header="Add Group"
        :visible.sync="displayCreateClientModal"
        :modal="true"
      >
        <new-client-form
          :initialClients="fetchedClients"
          :initialStatuses="this.initialStatuses"
          :shouldSave="shouldSaveClient"
          :resetValues="resetValues"
          @save-success="saveClientSuccess"
          @save-fail="saveClientFailed"
        ></new-client-form>
        <template #footer>
          <p-button class="cancel-btn" label="Cancel" @click="closeCreateClientModal"></p-button>
          <p-button class="save-btn" label="Add" @click="saveNewClient"></p-button>
        </template>
      </p-dialog>
    </div>
    <div v-if="subscriptions.length > 0">
      <subscriptions-table 
      :subscriptions="subscriptions"
       @open-create-client-modal="openCreateClientModal"
      />
    </div>

  </div>
</template>

<script>
import NewClientForm from './_newClientForm.vue'
import SubscriptionsTable from "../../components/subscriptionsTable.vue";
import Breadcrumbs from "../../components/styledBreadcrumb.vue";

import Button from 'primevue/button';
import Datatable from 'primevue/datatable';
import Column from 'primevue/column';
import {FilterMatchMode} from 'primevue/api';
import Dialog from 'primevue/dialog';

import axios from 'axios';

export default {
  components: {
    'new-client-form': NewClientForm,
    'subscriptions-table': SubscriptionsTable,
    'p-button': Button,
    'p-datatable': Datatable,
    'p-column': Column,
    'breadcrumbs': Breadcrumbs,
    'p-dialog': Dialog,
  },
  props: {
    clients: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Groups",
          url: "/clients",
        },
      ],
      fetchedClients: this._.clone(this.clients),
      subscriptions: [],
      // TODO: add filters back in for status
      filters: {
        'global': {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        'name': {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        'status_dup': {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
      initialStatuses: [
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'pending'},
        { label: 'Inactive', value: 'inactive' },
      ],
      displayCreateClientModal: false,
      newClient: {
        name: '',
        description: '',
        status: '',
        owner_id: 1,
      },
      shouldSaveClient: false,
      resetValues: false,
    }
  },
  methods: {
    fetchClients() {
      axios.get('/clients.json')
      .then((res) => {
        this.fetchedClients = res.data.clients;
        this.$forceUpdate();
      })
      .catch(err => {
        // TODO: error popup
        this.fetchedClients = [];
        this.$toast.add({severity:'error', summary: 'Error', detail:'error during processing fetched clients', life: 3000});
      })
    },
    saveNewClient() {
      this.shouldSaveClient = true;
    },
    saveClientSuccess() {
      this.shouldSaveClient = false;
      this.closeCreateClientModal();
      this.fetchClients();
      this.fetchSubscriptions();
    },
    saveClientFailed() {
      this.shouldSaveClient = false;
      this.resetValues = true;
    }, 
    openCreateClientModal() {
      this.displayCreateClientModal = true;
      this.resetValues = true;
    },
    closeCreateClientModal() {
      this.displayCreateClientModal = false;
      this.resetValues = false;
    },
    fetchSubscriptions() {
      axios.get('/subscriptions')
      .then((res) => {
        this.subscriptions = res.data.subscriptions;
      })
      .catch(err => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'error during processing fetched subscriptions', life: 3000});
      })

    },
  },
  mounted() {
    this.fetchSubscriptions()
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .p-datatable.p-datatable-gridlines.p-datatable-scrollable .p-datatable-tbody > tr > td + td {
    justify-content: center;
    width: 20vw;
  }

  ::v-deep .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #dbf3ff;
    color: #007bff;
}

  ::v-deep .p-datatable {
    .p-column-header-content {
      width: 100%;
      display: flex;
      padding: 0.2rem 1rem;
    }

    .p-datatable-header {
      background: transparent;
      border-width: 0px;
      padding: 1rem 0rem;
    }

    .client-column {
      width: 20vw;
      min-height: max-content;

      a {
        word-wrap: break-word
      }
    }

    .status-column {
      max-width: 3em;
      text-align: center;
    }
  }

.client-index-container {
  width: 100%;
  height: 100%;

  a {
    text-decoration: inherit;
  }

  .create-client-modal-container {
    ::v-deep div .p-dialog .p-dialog-content {
      width: 60vw;
      min-height: 62vh;
    }
  }

  .create-client-form {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 0.5em;
    }
  }

  .create-client-modal {
    width: 80%;
  }
}

.title-div{
  color: black;
  border-bottom: 1px solid #f2f3f9;
  padding-bottom: 19.5px;
}
</style>