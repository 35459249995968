<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-3">
        <h6>Name</h6>
        <p-input-text type="text" v-model="application.name" class="w-100" />
      </div>
      <div class="col-md-3">
        <h6>Owner</h6>
        <p-dropdown
            v-model="application.owner_id"
            :options="users"
            :filter="true"
            optionLabel="email"
            optionValue="id"
            class="w-100" />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-6">
        <h6>Description</h6>
        <p-input-text type="text" v-model="application.description" class="w-100" />
      </div>
    </div>


    <p-button icon="fas fa-save" iconPos="right" label="Save" @click="updateApplication($event)"/>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';

export default {
  components: {
    'p-button': Button,
    'p-dropdown': Dropdown,
    'p-input-text': InputText
  },
  data: function() {
    return {
      application: {},
      users: []
    }
  },
  props: {
    initialApplication: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateApplication(event) {
      axios.patch(`/applications/${this.application.id}`, this.application)
          .then((res) => {
            this.$toast.add({severity:'success', summary: 'Success', detail:'application record updated.', life: 3000});
            this.application = res.data.application;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'application could not be updated', life: 3000});
          })
    },
    getUsers() {
      axios.get('/users/')
          .then((res) => {
            this.users = res.data.users;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not fetch users', life: 3000});
          })
    }
  },
  mounted() {
    this.application = this.initialApplication;
    this.users = this.getUsers();
  },
  watch: {
    initialApplication: function() {
      this.application = this.initialApplication
    }
  }
}
</script>