<template>
  <div class="d-flex flex-row">
    <div class="w-100">
      <div class="d-flex flex-row justify-content-between flex-row-reverse">
        <div class="flex-grow-1" v-show="addRowVisible">
          <h5 class="mb-4">Add New Document</h5>
          <div class="d-flex flex-row">
            <div class="me-2 flex-grow-1">
            <span class="p-float-label">
              <p-inputtext v-model="newDocument.title" class="w-100" />
              <label>Title</label>
            </span>
            </div>
            <div class="me-2 flex-grow-1">
            <span class="p-float-label">
              <p-inputtext v-model="newDocument.link" class="w-100" />
              <label>Link</label>
            </span>
            </div>
            <div class="me-2">
            <span class="p-float-label">
              <p-autocomplete
                  :multiple="true"
                  v-model="newDocument.tags"
                  :suggestions="filteredTags"
                  @complete="searchTags($event)"
                  field="name"
              />
              <label>Tags</label>
            </span>
            </div>

            <p-button icon="fas fa-save" label="Save" @click="addNewDocument" :disabled="isSaveDisabled()" />
          </div>
        </div>
      </div>
      <p-datatable :value="documents" editMode="row" dataKey="id" :editingRows.sync="editingRows"
                   @row-edit-save="onUpdateDocumentRow" >

        <p-column header="Title" field="title">
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
        </p-column>
        <p-column header="Link" field="link">
          <template #editor="slotProps">
            <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
          </template>
          <template #body="slotProps">
            <a target="_blank" :href="slotProps.data[slotProps.column.field]">{{ slotProps.data[slotProps.column.field] }}</a>
          </template>
        </p-column>

        <p-column header="Tags" field="tags">
          <template #editor="{ data, field }">
            <p-autocomplete
                :multiple="true"
                v-model="data[field]"
                :suggestions="filteredTags"
                @complete="searchTags($event)"
                field="name"
            />
          </template>
          <template #body="slotProps">
            <div class="tag-display">
              <div
                  v-for="(tag, index) in slotProps.data[slotProps.column.field]"
                  :key="index"
              >
                <div class="chip" :style="{ backgroundColor: '#' + tag.color }">
                  {{ tag.name }}
                </div>
              </div>
            </div>
          </template>
        </p-column>

        <p-column header="Uploaded by" field="user.email">
        </p-column>

        <p-column :rowEditor="true" :styles="{width:'10px'}" :bodyStyle="{'text-align':'center'}"></p-column>
        <p-column :styles="{width:'10px'}" :bodyStyle="{'text-align':'center'}">
          <template #body="slotProps">
            <p-button icon="pi pi-trash" class="p-button-rounded p-button-text p-button-secondary" @click="deleteDocument(slotProps.data)" />
          </template>
        </p-column>
      </p-datatable>
    </div>
    <div class="ms-3 float-end flex-shrink-1">
      <p-button
          icon="fas fa-plus"
          label=""
          class="p-button-outlined" @click="addRowVisible = !addRowVisible"
      />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';

import axios from 'axios';
import tagsMixin from '../../components/mixins/tagsMixin';

export default {
  mixins: [tagsMixin],
  components: {
    'p-button': Button,
    'p-autocomplete': AutoComplete,
    'p-dropdown': Dropdown,
    'p-datatable': DataTable,
    'p-column': Column,
    'p-inputtext': InputText,
    'p-inputmask': InputMask,
    'p-inputnumber': InputNumber,
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      documents: [],
      editingRows: [],
      addRowVisible: false,
      newDocument: {
        subscription_id: this.subscription.id,
        title: null,
        link: null,
        tags: null,
      },
      parentType: 'Document'
    }
  },
  methods: {
    fetchDocuments() {
      axios.get('/documents?subscription_id=' + this.subscription.id)
          .then((res) => {
            this.documents = res.data.documents;
          })
          .catch(err => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Error fetching documents'});
          })
    },
    isSaveDisabled() {
      return this.newDocument.title === null || this.newDocument.link === null;
    },
    addNewDocument() {
      this.newDocument.model_tags_attributes = this.newDocument.tags?.map(x => { return { tag_template_id: x.id } } )
      axios.post('/documents/', this.newDocument)
          .then((res) => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Added document', life: 3000});
            this.fetchDocuments();
            this.newDocument = {
              subscription_id: this.subscription.id,
              title: null,
              link: null,
              tags: null,
            };
            this.addRowVisible = false;
          })
          .catch((error) => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to add document', life: 3000});
          })
    },
    onUpdateDocumentRow(event) {
      let {newData, index} = event;
      console.log(newData);
      newData.model_tags_attributes = newData.tags?.map(x => { return { tag_template_id: x.id } } )
      axios.patch(`/documents/${newData.id}`, newData)
          .then((res) => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Updated document detail', life: 3000});
            this.$set(this.documents, index, res.data.document);
          })
          .catch((error) => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to update document details', life: 3000});
          })
    },
    deleteDocument(data) {
      this.$confirm.require({
        message: 'Are you sure you want to delete this document?',
        header: 'Delete document',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          axios.delete(`/documents/${data.id}`)
              .then((res) => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Deleted document', life: 3000});
                this.fetchDocuments();
              })
              .catch((error) => {
                this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to delete document', life: 3000});
              })
        }
      });
    }
  },
  mounted() {
    this.fetchDocuments();
    this.getSuggestedTags();
  }
}
</script>

<style lang="scss" scoped>
.tag-display {
  display: flex;
  align-items: center;
}
</style>