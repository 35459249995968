import axios from "axios";

export default {
  data: function () {
    return {
      filteredTags: null,
    };
  },
  methods: {
    createTags() {
      axios
        .post(`/tags`, {
          tags: {
            tag_templates: this.suggestedTagsPayload(),
            parent_type: this.parentType,
            parent_id: this.parentId,
          },
        })
        .then((response) => {
          this.initialTags = response.data.tags;
          this.initialTagTemplates = this.tagTemplatesExtract(
            response.data.tags
          );
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Tags successfully updated",
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to update tags",
            life: 3000,
          });
        })
        .finally(() => {
          this.$emit("update-tags", this.initialTags);
          this.$emit("close-modal");
        });
    },

    suggestedTagsPayload() {
      return this.initialTagTemplates.map((tag) => {
        return {
          tag_template_id: tag.id,
        };
      });
    },

    tagTemplatesExtract(tags) {
      return tags.map((tag) => tag.tag_template);
    },
    searchTags(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredTags = [...this.suggestedTags];
        } else {
          this.filteredTags = this.suggestedTags.filter((suggestedTag) => {
            return suggestedTag.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    getSuggestedTags() {
      axios
        .get(`/tag_templates/?target_type=${this.parentType}`)
        .then((response) => {
          if (response.data) {
            this.suggestedTags = response.data.tag_templates;
          }
        });
    },

    cancelTagModal() {
      this.initialTags = [...this.tags];
      this.$emit("close-modal");
    },
  },
  watch: {
    parentType: function () {
      this.getSuggestedTags();
    },

    tags: function () {
      this.initialTags = [...this.tags];
      this.initialTagTemplates = this.tagTemplatesExtract(this.initialTags);
    },
  },
};
