<template>
  <div>
    <h2>Admin Management</h2>
    <p-tag-manager />
  </div>
</template>

<script>
import Button from "primevue/button/Button";
import TagManager from "./tag_manager/tag-manager";

export default {
  components: {
    "p-tag-manager": TagManager,
    "p-button": Button,
  },
  props: {},
  data: function () {
    return {};
  },
};
</script>