<template>
  <div>
    <breadcrumbs
      :breadcrumbs="breadcrumbs"
    ></breadcrumbs>
    <h2>{{ client.name }}</h2>
    <client-form
        :initialClient="client"
        :shouldSave="shouldSave"
        @save-success="onSaveSuccess"
        @save-fail="onSaveFailed"
    ></client-form>
    <p-button label="Save" icon="fas fa-save" @click="save" autofocus />
  </div>
</template>

<script>
import ClientForm from './_form'

import Button from 'primevue/button';
import Breadcrumbs from '../../components/styledBreadcrumb.vue';

export default {
  components: {
    'client-form': ClientForm,
    'p-button': Button,
    'breadcrumbs': Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: 'Groups',
          url: '/clients'
        },
        {
          label: this.client.name,
          url: `/clients/${this.client.id}`
        },
        {
          label: 'Edit'
        }
      ],
      shouldSave: false
    }
  },
  props: {
    client: {
      type: Object,
      require: true
    }
  },
  methods: {
    save() {
      this.shouldSave = true;
    },
    onSaveSuccess() {
      this.shouldSave = false;
      window.location.replace(`/clients/${this.client.id}`)
    },
    onSaveFailed() {
      this.shouldSave = false;
    }
  }
}
</script>