<template>
  <div v-if="editable == true" class="form-wrapper d-flex align-items-start flex-column" >
    <div class="mb-auto w-100"><slot name="edit-form"></slot></div>
    <hr>
    <div class="d-flex flex-row-reverse w-100 buttons-container">
      <p-button class="save-btn" label="Save" @click="onSave"></p-button>
      <p-button class="cancel-btn" label="Cancel" @click="onCancel"></p-button>
    </div>
  </div>
  <div v-else>
    <slot name="form"></slot>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  components: {
    'p-button': Button,
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSave() {
      // Call save() function that is in the form
      if(this.$slots?.form[0]?.context.$refs?.form?.save == undefined) {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Missing save function.', life: 3000});
      } else {
        this.$slots.form[0].context.$refs.form.save()
      }
    },
    onCancel() {
      // Call reset() function that is in the form 
      if(this.$slots?.form[0]?.context.$refs?.form?.reset == undefined) {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Missing reset function.', life: 3000});
      } else {
        this.$slots.form[0].context.$refs.form.reset()
      }
    },
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
  .form-wrapper {
    min-height: 30vh;
    min-width: 45vw;
    
    hr {
      width: 100%;
      border: solid 1px #dce1e7;
      color: #dce1e7;
    }
  }

  .buttons-container {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
</style>