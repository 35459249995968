<template>
  <div>
    <div class="tag-display">
      <div v-for="(tag, index) in tags" :key="index">
        <div
          class="chip"
          :style="{ backgroundColor: '#' + tag.tag_template.color }"
        >{{ tag.tag_template.name }}</div>
      </div>
      <div class="add-tag-btn">
        <p-button class="add-tag-button" @click="openModal">
          + <span>Add new tag</span>
        </p-button
        >
      </div>
    </div>
    <div class="tag-section-modal">
      <tag-modal
        :modalVisible="modalVisible"
        :tags="tags"
        :parentType="parentType"
        :parentId="parentId"
        @close-modal="closeModal()"
        @update-tags="updateTags($event)"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";

import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";

import tagSectionModal from "./tagSectionModal.vue";

export default {
  components: {
    "p-button": Button,
    "p-autocomplete": AutoComplete,
    "p-dialog": Dialog,
    "tag-modal": tagSectionModal,
  },
  data() {
    return {
      selectedTags: [],
      tags: [],
      filteredTags: null,
      parentType: null,
      parentId: null,
      modalVisible: false,
    };
  },
  props: {
    client: {
      type: Object,
      require: false,
      default: null,
    },
    configurationItem: {
      type: Object,
      require: false,
      default: null,
    },
    applicationGroup: {
      type: Object,
      require: false,
      default: null,
    },
    subscription: {
      type: Object,
      require: false,
      default: null,
    }
  },

  methods: {
    getTags() {
      axios
        .get(`/tags/?parent_type=${this.parentType}&parent_id=${this.parentId}`)
        .then((response) => {
          if (response.status === 200) {
            this.tags = response.data.tags;
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.error || error);
        });
    },
    updateTags(tags) {
      this.tags = tags;
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },

    getTagTemplates() {},

    getParentType() {
      if (this.client) {
        this.parentType = "Client";
        this.parentId = this.client.id;
      } else if (this.configurationItem) {
        this.parentType = "ConfigurationItem";
        this.parentId = this.configurationItem.id;
      } else if (this.subscription) {
        this.parentType = "Subscription";
        this.parentId = this.subscription.id;
      } else {
        this.parentType = "ApplicationGroup";
        this.parentId = this.applicationGroup.id;
      }
    },
  },

  computed: {},
  mounted() {
    this.getParentType();
    this.getTags();
  },
};
</script>

<style lang="scss" scoped>
  .tag-display {
    display: flex;
    align-items: center;
  }

  ::v-deep .add-tag-button{
    &.p-button {
      background-color: inherit;
      color: #0d3ef0;
      border: 0px;

      span {
        text-decoration: underline;
        margin-left: 0.2rem;
      }
    }
  }
</style>
