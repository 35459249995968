<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <component
        :is="reportComponent"
        :reportName="reportName"
        :reportData="reportData"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/styledBreadcrumb.vue";

export default {
  components: {
    'breadcrumbs': Breadcrumbs,
  },
  props: {
    reportName: {
      type: String,
      required: true
    },
    reportData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Reports",
          url: "/reports",
        },
        {
          label: this._.startCase(this._.camelCase(this.reportName)),
          url: `/reports/${this.reportName}`
        }
      ],
    }
  },
  methods: {

  },
  computed: {
    reportComponent() {
      return () => import(`./report_templates/${this.reportName}.vue`)
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.report-icon {
  font-size: 80px;
}
</style>