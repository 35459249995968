<template>
  <div class="w-100 h-100 d-flex" :class="getClass()">
    <div class="float-label mx-3">
      <label>Primary Contact:</label>
      <p-dropdown
        class="input-width input-display-text"
        v-model="vertoPrimaryContact"
        :options="vertoContacts"
        optionValue="id"
        :disabled="!editable"
        showClear
      >
        <template #option="slotProps">
          <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }} - {{ slotProps.option.email}}</span>
        </template>
        <template #value="slotProps">
          <span v-if="slotProps.value">
            {{ vertoPrimaryContactObject(slotProps.value).first_name }}
            {{ vertoPrimaryContactObject(slotProps.value).last_name }}
            -
            {{ vertoPrimaryContactObject(slotProps.value).email}}
          </span>
          <span v-else>
            {{slotProps.placeholder}}
          </span>
        </template>
      </p-dropdown>
    </div>
    <div class="float-label mx-3">
      <label>Additional Contact:</label>
      <p-autocomplete
        class="input-width input-display-text"
        :multiple="true"
        v-model="vertoAdditionalContacts"
        :suggestions="filteredContacts"
        @complete="searchContacts"
        :disabled="!editable"
        :field="displayAdditionalContactChips"
      >
      <template #item="slotProps">
        {{ slotProps.item.first_name }} {{ slotProps.item.last_name }} - {{ slotProps.item.email }}
      </template>
      </p-autocomplete>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Listbox from 'primevue/listbox';
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    'p-button': Button,
    'p-input-text': InputText,
    'p-dropdown': Dropdown,
    'p-listbox': Listbox,
    'p-autocomplete': AutoComplete,
  },
  props: {
    initialInternalContacts: {
      type: Array,
      required: false
    },
    editable: {
      type: Boolean,
      default: false,
    },
    parentModel: {
      type: String,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      contact: {},
      internalContacts: this._.clone(this.initialInternalContacts),
      contact_priorities: [
        { label: 'Primary', value: 'primary' },
        { label: 'Secondary', value: 'secondary' },
        { label: 'Other', value: 'other' },
      ],
      vertoPrimaryContact: null,
      vertoAdditionalContacts: [],
      vertoContacts: [],
      filteredContacts: null,
      internalContactsChange: false,
    }
  },
  methods: {
    getInternalContacts() {
      axios.get('/contacts?contact_type=internal')
      .then((res) => {
        this.vertoContacts = res.data.contacts.sort((a,b) => a.first_name.localeCompare(b.first_name));
      })
      .catch((error) => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Could not reach verto contact list.', life: 3000});
      })
    },
    displayAdditionalContactChips(contact) {
      return `${contact.first_name} ${contact.last_name}`
    },
    removeIdFromArray(array, id) {
      let targetIndex = array.findIndex((obj) =>　obj['id'] == id);
      if(targetIndex > -1) {
        array = array.splice(targetIndex, 1)
      }
    },
    searchContacts(event) {
      setTimeout(() => {
        if(!event.query.trim().length) {
          this.filteredContacts = [...this.vertoContacts];
        } else {
          this.filteredContacts = this.vertoContacts.filter((contact) => {
            return contact.first_name.toLowerCase().startsWith(event.query.toLowerCase()) ||
              contact.last_name.toLowerCase().startsWith(event.query.toLowerCase());
          })
        }
      })
    },
    save() {
      this.removeIdFromArray(this.vertoAdditionalContacts, this.vertoPrimaryContact);
      return axios.post(`/link_contact`, {
        contactable_id: this.parentId,
        contactable_type: 'Subscription',
        primary: this.vertoPrimaryContact,
        other: this.vertoAdditionalContacts.map((contact) => contact.id)
      })
        .then((res) => {
          this.$toast.add({severity:'success', summary: 'Success', detail:'Internal contacts linked.', life: 3000});
          this.internalContacts = res.data.contacts.filter((contact)=> contact.contact.contact_type=="internal");
          this.$emit('save-success', this.internalContacts);
        })
        .catch((error) => {
          this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000});
          this.$emit('save-fail', error);
        }
      );
    },
    reset() {
      this.setInitialKeyContacts();
      this.internalContacts = this._.clone(this.initialInternalContacts)
      this.$emit('close');
    },
    setInitialKeyContacts() {
      if(this.internalContacts) {
        this.vertoPrimaryContact = (this.internalContacts.find(contact => contact.contact_priority == 'primary')) ?
          (this.internalContacts.find(contact => contact.contact_priority == 'primary'))['contact_id'] :
          null;
        this.vertoSecondaryContact = (this.internalContacts.find(contact => contact.contact_priority == 'secondary')) ?
          (this.internalContacts.find(contact => contact.contact_priority == 'secondary'))['contact_id'] :
          null;
        this.vertoAdditionalContacts = (this.internalContacts.filter(contact => contact.contact_priority == 'other')).map(contact => contact['contact'])
      }
    },
    getClass() {
      if(this.editable) {
        return 'input-width-edit'
      } else {
        return 'input-width-display'
      }
    },
    vertoPrimaryContactObject(id) {
      return this.vertoContacts.filter(x => x.id === id)[0]
    }
  },
  mounted() {
    this.getInternalContacts();
    this.setInitialKeyContacts();
  }
}
</script>

<style lang="scss" scoped>
  .external-contact-box {
    padding: 0.5rem;
    margin: 0.1rem;
    width: 100%;
    border: 1px solid grey;
    border-radius: 30px;
  }

  .input-width-edit {
    flex-wrap: wrap;

    .input-width {
      width: 45vw;

      ::v-deep .p-autocomplete-multiple-container  {
        width: 45vw;
      }
    }
  }

  .input-width-display {
    .input-width {
      width: 25vw;

      ::v-deep .p-autocomplete-multiple-container  {
        width: 25vw;
      }
    }
  }

  ::v-deep .p-listbox .p-listbox-list {
    padding: 0;
  }

</style>