<template>
  <div class="show-ci">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div class="p-2">
      <h1 class="my-2">
        <strong>{{ applicationGroup.name }}</strong>
      </h1>

      <div class="d-flex flex-row justify-content-between my-2">
        <h3 class="text-primary my-4">APPLICATION INFORMATION</h3>
        <p-button
          label="Link applications"
          @click="openLinkApplicationModal"
        ></p-button>
      </div>

      <p-dialog
        header="Link applicaiton"
        :visible.sync="linkApplicationModalVisible"
        v-click-modal-outside="closeLinkApplicationModal"
        :style="{ width: '70vw' }"
        :modal="true"
      >
        <div>
          <h4>Add existing application</h4>
          <p-listbox
            key="id"
            v-model="selectedApplications"
            :options="availableApplications"
            optionLabel="name"
            optionValue="id"
            filterPlaceholder="Search"
            :filter="true"
            :multiple="true"
          >
            <template #option="slotProps">
              {{ slotProps.option.name
              }}{{
                slotProps.option.description.length > 0
                  ? ` - ${slotProps.option.description}`
                  : ""
              }}
            </template>
          </p-listbox>
          <div class="d-flex flex-row-reverse my-3">
            <p-button
              label="Link applications"
              @click="linkSelectedApplications"
            ></p-button>
          </div>
        </div>
        <div class="d-flex flex-column">
          <h4>Add new application</h4>
          New application name:
          <p-inputtext v-model="newApplicationName"></p-inputtext>
          New application description:
          <p-textarea v-model="newApplicationDescription"></p-textarea>
          <div class="w-100 d-flex flex-row-reverse my-4">
            <p-button label="Save" @click="saveNewApplication"></p-button>
          </div>
        </div>
      </p-dialog>

      <p-tab-view :activeIndex="initialAppIndex" scrollable>
        <p-tab-panel
          v-for="app in applications"
          :key="app.id"
          :header="`${app.name}`"
        >
          <p-button
            class="w-100 my-3"
            label="Unlink"
            @click="unlinkApplication(app.id)"
          ></p-button>
          <application
            :initial-application="app"
            :initialClient="initialClient"
            :initialPod="initialApplicationGroup.name"
          ></application>
        </p-tab-panel>
      </p-tab-view>
    </div>
    <div>
      <tag-section :applicationGroup="initialApplicationGroup" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

import overviewBox from "../../components/overviewBox";
import parsedDate from "../../components/parsedDate";
import Breadcrumbs from "../../components/styledBreadcrumb.vue";
import ApplicationShow from "../applications/show.vue";
import tagSection from "../../components/tagSection.vue";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Listbox from "primevue/listbox";

export default {
  components: {
    application: ApplicationShow,
    "overview-box": overviewBox,
    "parsed-date": parsedDate,
    "tag-section": tagSection,
    "p-button": Button,
    "p-dialog": Dialog,
    breadcrumbs: Breadcrumbs,
    "p-tab-menu": TabMenu,
    "p-tab-view": TabView,
    "p-tab-panel": TabPanel,
    "p-inputtext": InputText,
    "p-textarea": Textarea,
    "p-listbox": Listbox,
  },
  data() {
    return {
      applicationGroup: this._.clone(this.initialApplicationGroup),
      linkApplicationModalVisible: false,
      tags: [],
      application: {},
      applications: this.initialApplicationGroup.applications,
      breadcrumbs: [
        {
          label: "Clients",
          url: "/clients",
        },
        {
          label: this.initialClient.name,
          url: `/clients/${this.initialClient.id}`,
        },
        {
          label: `${
            this.applicationGroup
              ? this.applicationGroup.name
              : this.initialApplicationGroup.name
          }`,
        },
      ],
      items: [
        { label: "Home", icon: "pi pi-fw pi-home", to: "/clients" },
        {
          label: "Calendar",
          icon: "pi pi-fw pi-calendar",
          to: "/applications",
        },
      ],
      initialAppIndex: -1,
      newApplicationName: "",
      newApplicationDescription: "",
      selectedApplications: [],
      availableApplications: [],
    };
  },
  props: {
    initialApplicationGroup: {
      type: Object,
      required: true,
    },
    initialClient: {
      type: Object,
      required: true,
    },
    initialApp: {
      type: String,
      default: "-1",
    },
  },
  methods: {
    getApplicationGroup() {
      axios
        .get(`/application_groups/${this.initialApplicationGroup.id}`)
        .then((res) => {
          this.applicationGroup = res.data.application_group;
          this.applications = [];
          this.applications = res.data.application_group.applications;
        })
        .catch((error) => {
          this.applicationGroup = this.initialApplicationGroup;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "failed to get application group",
            life: 3000,
          });
        });
    },
    saveNewApplication() {
      axios
        .post("/applications", {
          name: this.newApplicationName,
          description: this.newApplicationDescription,
        })
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "successfully added new application.",
            life: 3000,
          });
          this.getAvailableApplications();
          this.selectedApplications.push(res.data.application);
          this.newApplicationName = '';
          this.newApplicationDescription = '';
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "failed to add new application.",
            life: 3000,
          });
        });
    },
    getAvailableApplications() {
      const comparator = (x, y) => x.id === y.id;
      const appDifference = (all, cur, comparator) =>
        all.filter((allApp) => !cur.some((app) => comparator(allApp, app)));
      axios
        .get("/applications")
        .then((res) => {
          this.availableApplications = appDifference(
            res.data.applications,
            this.applicationGroup.applications,
            comparator
          );
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "failed to get application.",
            life: 3000,
          });
        });
    },
    linkSelectedApplications() {
      axios
        .patch(`/application_groups/${this.applicationGroup.id}`, {
          application_ids: this.applicationGroup.applications
            .map((x) => x.id)
            .concat(this.selectedApplications),
        })
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "successfully linked new application.",
            life: 3000,
          });
          this.getApplicationGroup();
          this.getAvailableApplications();
          this.$forceUpdate();
          this.closeLinkApplicationModal();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "failed to add new application.",
            life: 3000,
          });
        });
    },
    unlinkApplication(unlinkAppId) {
      axios
        .patch(`/application_groups/${this.applicationGroup.id}`, {
          application_ids: this.applicationGroup.applications
            .filter((x) => x.id != unlinkAppId)
            .map((x) => x.id),
        })
        .then((res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "successfully unlinkeded application.",
            life: 3000,
          });
          // this.getApplicationGroup();
          window.location.reload();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "failed to unlink application.",
            life: 3000,
          });
        });
    },
    openLinkApplicationModal() {
      this.linkApplicationModalVisible = true;
    },
    closeLinkApplicationModal() {
      this.linkApplicationModalVisible = false;
    },
  },
  mounted() {
    this.applicationGroup = this.initialApplicationGroup;
    this.getApplicationGroup();
    this.getAvailableApplications();
    if (this.initialApp === -1) {
      this.application = this.applicationGroup.applications[0];
    } else {
      this.initialAppIndex = 0;
      this.application =
        this.applicationGroup.applications[this.initialAppIndex];

      // Hack so that tab exists when the initalAppIndex is set. Otherwise
      // TabView component will error out trying to scroll into non existing
      // element
      setTimeout(() => {
        this.initialAppIndex = this.applicationGroup.applications.findIndex(
          (app) => app.id == this.initialApp
        );
        if (this.initialAppIndex === -1) {
          this.initialAppIndex = 0;
        }
      });
    }
  },
};
</script>