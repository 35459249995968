<template>
  <div class="d-flex flex-row">
    <div class="flex-1 w-100 me-2">
      <div v-show="addRowVisible">
        <h5 class="mb-4">Add New Subscription</h5>
        <div class="d-flex flex-row">
          <div class="me-2">
            <span class="p-float-label">
              <p-calendar v-model="newContract.start_date"/>
              <label>Start Date</label>
            </span>
          </div>
          <div class="me-2">
            <span class="p-float-label">
              <p-inputnumber suffix=" days" v-model="newContract.term_days"/>
              <label>Term (Days)</label>
            </span>
          </div>
          <div class="me-2">
            <span class="p-float-label">
              <p-inputnumber v-model="newContract.vsu"/>
              <label>Number of VSU</label>
            </span>
          </div>
          <div class="me-2">
            <span class="p-float-label">
              <p-dropdown v-model="newContract.status" :options="statuses" optionLabel="label" optionValue="value"/>
              <label>Status</label>
            </span>
          </div>
          <p-button icon="fas fa-save" label="Save" @click="addNewContract" :disabled="isSaveDisabled()" />
        </div>
      </div>
      <p-datatable :value="contracts" editMode="row" :rowClass="rowClass" dataKey="id" :editingRows.sync="editingRows"
                 @row-edit-save="onUpdateContractRow">
      <p-column header="Start Date" field="start_date">
        <template #editor="slotProps">
          <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </p-column>
      <p-column header="End Date" field="end_date">
        <template #editor="slotProps">
          <p-inputtext v-model="slotProps.data[slotProps.column.field]" disabled />
        </template>
      </p-column>
      <p-column header="Term (Days)" field="term_days">
        <template #editor="slotProps">
          <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </p-column>
      <p-column header="VSU" field="vsu">
        <template #editor="slotProps">
          <p-inputtext v-model="slotProps.data[slotProps.column.field]" />
        </template>
      </p-column>
      <p-column header="Status" field="status">
        <template #editor="{ data, field }">
          <p-dropdown v-model="data[field]" :options="statuses" optionLabel="label" optionValue="value"
                      placeholder="Select a Status">
          </p-dropdown>
        </template>
      </p-column>
      <p-column :rowEditor="true" :styles="{width:'5%', 'min-width':'8rem'}"
                :bodyStyle="{'text-align':'center'}"></p-column>
      <p-column :styles="{width:'5%', 'min-width':'8rem'}" :bodyStyle="{'text-align':'center'}">
        <template #body="slotProps">
          <p-button icon="pi pi-trash" class="p-button-rounded p-button-text p-button-secondary" @click="deleteContract(slotProps.data)" />
        </template>
      </p-column>
    </p-datatable>

    </div>
    <div class="flex-shrink-1 d-flex flex-row justify-content-between flex-row-reverse">
      <div class="float-end">
        <p-button
            icon="fas fa-add"
            label=""
            class="p-button-outlined" @click="addRowVisible = !addRowVisible"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';

import axios from 'axios';

export default {
  components: {
    'p-button': Button,
    'p-autocomplete': AutoComplete,
    'p-dropdown': Dropdown,
    'p-datatable': DataTable,
    'p-column': Column,
    'p-inputtext': InputText,
    'p-inputmask': InputMask,
    'p-inputnumber': InputNumber,
    'p-calendar': Calendar,
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contracts: [],
      editingRows: [],
      statuses: [
        {label: 'Active', value: 'Active'},
        {label: 'In Renewal', value: 'In Renewal'},
        {label: 'Renewed', value: 'Renewed'},
        {label: 'Churned', value: 'Churned'},
      ],
      addRowVisible: false,
      newContract: {
        subscription_id: this.subscription.id,
        start_date: null,
        term_days: 365,
        vsu: null,
        status: 'Active'
      }
    }
  },
  methods: {
    fetchContracts() {
      axios.get('/contracts?subscription_id=' + this.subscription.id)
          .then((res) => {
            this.contracts = res.data.contracts;
          })
          .catch(err => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Error fetching contracts'});
          })
    },
    maxContractId() {
      return this._.max([this._.maxBy(this.contracts, (o) => {
        return o?.contract_sequence
      })?.contract_sequence, 0]);
    },
    rowClass(data) {
      return data.contract_sequence === this.maxContractId() ? 'active-row' : 'inactive-row'
    },
    isSaveDisabled() {
      return this.newContract.start_date === null ||
          this.newContract.term_days === null ||
          this.newContract.vsu === null ||
          this.newContract.status === null;
    },
    addNewContract() {
      this.newContract.contract_sequence = this.maxContractId() + 1;
      axios.post('/contracts/', this.newContract)
          .then((res) => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Added subscription detail', life: 3000});
            this.fetchContracts();
            this.newContract = {
                subscription_id: this.subscription.id,
                start_date: null,
                term_days: 365,
                vsu: null,
                status: 'Active'
            };
            this.addRowVisible = false;
          })
          .catch((error) => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to add subscription details', life: 3000});
          })
    },
    onUpdateContractRow(event) {
      let {newData, index} = event;
      axios.patch(`/contracts/${newData.id}`, newData)
          .then((res) => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Updated subscription detail', life: 3000});
            this.$set(this.contracts, index, res.data.contract);
          })
          .catch((error) => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to update subscription details', life: 3000});
          })
    },
    deleteContract(data) {
      console.log('I just run...')
      console.log(data)
      this.$confirm.require({
        message: 'Are you sure you want to delete this contract?',
        header: 'Delete subscription details',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          axios.delete(`/contracts/${data.id}`)
              .then((res) => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Deleted subscription detail', life: 3000});
                this.fetchContracts();
              })
              .catch((error) => {
                this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to delete subscription details', life: 3000});
              })
        }
      });
    }
  },
  mounted() {
    this.fetchContracts()
  }
}
</script>

<style lang="scss">
.active-row {
  background-color: #e9f7ff !important;
  font-weight: 500;
}

.inactive-row {
  color: #777777 !important;
  font-style: italic;
}
</style>