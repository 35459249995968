<template>
  <div class="m-2">
    <div class="d-flex edit-icon">
      <div class="me-auto">
        <span><b>Description: </b> <br/> <i>{{initialSubscription.description}}</i></span>
      </div>
      <span class="d-flex text-nowrap flex-column me-5 my-auto">
        <table>
          <tr>
            <td class="text-end" >
              <i class="fa-regular fa-circle-question" v-tooltip.left="'Verto assigned short code'"></i>
              <b>Identifier:</b>
            </td>
            <td class="ps-2">
              <code class="user-select-all">{{ initialSubscription.identifier }}</code>
              <i class="fa-regular fa-clipboard fa-xs" v-clipboard="initialSubscription.identifier"></i>
            </td>
          </tr>
          <tr>
            <td class="text-end">
              <i class="fa-regular fa-circle-question" v-tooltip.left="'Deal ID assigned by Hubspot'"></i>
              <b>Hubspot ID:</b>
            </td>
            <td class="ps-2">
              <code class="user-select-all">{{ initialSubscription.external_id }}</code>
              <i class="fa-regular fa-clipboard fa-xs" v-clipboard="initialSubscription.external_id"></i>
            </td>
          </tr>
<!--          <tr>-->
<!--            <td class="text-end">-->
<!--              <i class="fa-regular fa-circle-question" v-tooltip.left="'ID used by dev/devops/data to identify client resources'"></i>-->
<!--              <b>Dev Code:</b>-->
<!--            </td>-->
<!--            <td class="ps-2">-->
<!--              <code class="user-select-all">{{ initialSubscription.dev_code }}</code>-->
<!--              <i class="fa-regular fa-clipboard fa-xs" v-clipboard="initialSubscription.dev_code"></i>-->
<!--            </td>-->
<!--          </tr>-->
        </table>
      </span>
      <p-button class="edit-icon p-button-lg" icon="fas fa-edit" @click="openSubscriptionModal()"></p-button>
      <subscription-form
        :subscriptionModal="subscriptionModal"
        :initialSubscription="initialSubscription"
        v-on:close-subscription-modal="closeSubscriptionModal"
        v-on:save-success="onSubscriptionSaveSuccess"
      >
      </subscription-form>
    </div>
    <hr/>
    <tag-section :subscription="initialSubscription" />

    <!-- Contacts Accordion -->
    <accordion-form
      ref="contactsAccordion"
      tabHeader="Key Contacts"
      :empty="isEmptyField(internalContacts)"
      :formConfigs="[
        {
          name: 'Internal Contacts',
        },
        {
          name: 'External Contacts',
          style: { width: '90vw' },
        },
      ]"
      :formNames="['Internal Contacts', 'External Contacts']"
      :hasNotes="false"
      :openNotesModal="closeNotesToggle"
    >
      <template slot="Internal Contacts header"
        >Edit Internal Contacts</template
      >
      <template slot="Internal Contacts" slot-scope="props">
        <form-interface :editable="props.editable">
          <template v-slot:edit-form>
            <internal-contact-form
              ref="form"
              :initialInternalContacts="internalContacts"
              parentModel="Subscription"
              :parentId="subscription.id"
              :editable="true"
              @save-success="onInternalContactSaveSuccess"
              @close="closeModals('contactsAccordion')"
            ></internal-contact-form>
          </template>
          <template v-slot:form>
            <internal-contact-form
              :key="internalContactFormKey"
              ref="form"
              :initialInternalContacts="internalContacts"
              parentModel="Subscription"
              :parentId="subscription.id"
              :editable="false"
            ></internal-contact-form>
          </template>
          <template #empty> No key contacts. </template>
        </form-interface>
      </template>
      <template slot="External Contacts header"
        >Edit External Contacts</template
      >
      <template slot="External Contacts" slot-scope="props">
        <form-interface :editable="props.editable">
          <template #edit-form>
            <users-table-form
              ref="form"
              :subscription="subscription"
              :editable="true"
              :form-parent="'overview'"
              :contact-type="'external'"
              @save-success="onExternalContactSaveSuccess"
              @close="closeModals('contactsAccordion')"
            ></users-table-form>
          </template>
          <template #form>
            <users-table-form
              ref="form"
              :subscription="subscription"
              :editable="false"
              :form-parent="'overview'"
              :contact-type="'external'"
              @save-success="onExternalContactSaveSuccess"
            ></users-table-form>
          </template>
          <template #empty> No external contacts. </template>
        </form-interface>
      </template>
    </accordion-form>

    <!-- Subscription Details -->
    <p-accordion class="my-3">
      <p-accordion-tab header='Subscription Details'>
        <contracts-section :subscription="this.initialSubscription"></contracts-section>
      </p-accordion-tab>
    </p-accordion>

    <!-- Subscription Details -->
    <p-accordion class="my-3">
      <p-accordion-tab header='Implementations'>
        <implementations-section :subscription="this.initialSubscription"></implementations-section>
      </p-accordion-tab>
    </p-accordion>

    <!-- Support Accordion -->
    <accordion-form
      ref="supportAccordion"
      tabHeader="Support Overview"
      :empty="supportInformationEmpty"
      :formConfigs="[
        {
          name: 'Support Information',
          style: { width: '90vw' },
        },
        {
          name: 'Current Zendesk Users',
          style: { width: '90vw' },
        },
      ]"
    >
      <template slot="Support Information header"
        >Edit Support Overview</template
      >
      <template slot="Support Information" slot-scope="props">
        <form-interface :editable="props.editable">
          <template #edit-form>
            <support-form
              ref="form"
              :initialSupportInformation="supportOverview"
              :subscription="subscription"
              :editable="true"
              :form-parent="'overview'"
              @save-success="onSupportSaveSuccess"
              @close="closeModals('supportAccordion')"
            ></support-form>
          </template>
          <template #form>
            <support-form
              ref="form"
              :initialSupportInformation="supportOverview"
              :subscription="subscription"
              :editable="false"
              :form-parent="'overview'"
              @save-success="onSupportSaveSuccess"
            ></support-form>
          </template>
        </form-interface>
      </template>
      <template slot="Current Zendesk Users header"
        >Edit Zendesk Users</template
      >
      <template slot="Current Zendesk Users" slot-scope="props">
        <authorized-users-section :subscription="subscription" />
<!--        <form-interface :editable="props.editable">-->
<!--          <template #edit-form>-->
<!--            <users-table-form-->
<!--              ref="form"-->
<!--              :initialSupportInformation="supportOverview"-->
<!--              :subscription="subscription"-->
<!--              :editable="true"-->
<!--              :form-parent="'overview'"-->
<!--              :contact-type="'zendesk'"-->
<!--              @save-success="onZendeskUsersSaveSuccess"-->
<!--              @close="closeModals('supportAccordion')"-->
<!--            ></users-table-form>-->
<!--          </template>-->
<!--          <template #form>-->
<!--            <users-table-form-->
<!--              ref="form"-->
<!--              :initialSupportInformation="supportOverview"-->
<!--              :subscription="subscription"-->
<!--              :editable="false"-->
<!--              :contact-type="'zendesk'"-->
<!--              :form-parent="'overview'"-->
<!--              @save-success="onZendeskUsersSaveSuccess"-->
<!--            ></users-table-form>-->
<!--          </template>-->
<!--        </form-interface>-->
      </template>
      <template #empty> No support information. </template>
    </accordion-form>

    <p-accordion class="my-3">
      <p-accordion-tab header='Documents'>
        <documents-section :subscription="this.initialSubscription"></documents-section>
      </p-accordion-tab>
    </p-accordion>

    <!-- Reporting Accordion -->
    <accordion-form
        v-if="false"
        ref="additionalMaterialsAccordion"
        tabHeader="Reporting and Analytics"
        :empty="isEmptyField(subscription.additional_materials)"
        :hasNotes="true"
        :openNotesModal="closeNotesToggle"
    >
      <template #header>Edit Additional Materials</template>
      <template #accordionForm="props">
        <form-interface :editable="props.editable">
          <template v-slot:edit-form>
            <additional-materials-form
                ref="form"
                :initialSubscription="subscription"
                :editable="true"
                @save-success="onAdditionalMaterialsSaveSuccess"
                @close="closeModals('additionalMaterialsAccordion')"
            ></additional-materials-form>
          </template>
          <template v-slot:form>
            <additional-materials-form
                :key="additionalMaterialsFormKey"
                ref="form"
                :initialSubscription="subscription"
                :editable="false"
                @save-success="onAdditionalMaterialsSaveSuccess"
            ></additional-materials-form>
          </template>
        </form-interface>
      </template>
      <template #empty> No additional materials. </template>
      <template #notes>
        <notes-form
            :initialNotes="subscription.additional_materials_notes"
            noteType="additional_materials_notes"
            :subscriptionId="subscription.id"
            :editable="false"
        ></notes-form>
      </template>
      <template #edit-notes>
        <notes-form
            :initialNotes="subscription.additional_materials_notes"
            noteType="additional_materials_notes"
            :subscriptionId="subscription.id"
            :editable="true"
            @save-notes-success="onSaveNotesSuccess"
        ></notes-form>
      </template>
    </accordion-form>

    <!-- COGS Accordion -->
    <p-accordion v-if="false">
      <p-accordion-tab header='COGS'>
        <cogs-section :subscription="this.initialSubscription"></cogs-section>
      </p-accordion-tab>
    </p-accordion>
  </div>
</template>

<script>
import NotesForm from "../../components/notesForm.vue";
import ContractOverviewForm from "../contract_information/_form.vue";
import SupportOverviewForm from "../support_information/_form.vue";
import InternalContactForm from "../contacts/_internalContactsForm.vue";
import AdditionalMaterialsForm from "../clients/_additionalMaterialsForm.vue";
import SubscriptionForm from "../subscriptions/_form.vue"
import AccordionForm from "../../components/accordionForm.vue";
import FormInterface from "../../components/formInterface.vue";
import TagSection from "../../components/tagSection.vue";
import UsersTableForm from "../../components/usersTableForm.vue";
import CogsSection from "../cogs/index.vue";
import ContractsSection from "../contracts/index.vue";
import DocumentsSection from "../documents/index.vue";
import ImplementationsSection from "../implementations/index.vue"
import AuthorizedUsersSection from "../support_information/authorized_users";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Carousel from "primevue/carousel";
import ScrollPanel from "primevue/scrollpanel";
import TextArea from "primevue/textarea/Textarea";
import Dialog from "primevue/dialog/Dialog";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default {
  components: {
    "contract-form": ContractOverviewForm,
    "support-form": SupportOverviewForm,
    "internal-contact-form": InternalContactForm,
    "additional-materials-form": AdditionalMaterialsForm,
    "subscription-form": SubscriptionForm,
    "users-table-form": UsersTableForm,
    "notes-form": NotesForm,
    "accordion-form": AccordionForm,
    "form-interface": FormInterface,
    "tag-section": TagSection,
    "cogs-section": CogsSection,
    "contracts-section": ContractsSection,
    "documents-section": DocumentsSection,
    "implementations-section": ImplementationsSection,
    "authorized-users-section": AuthorizedUsersSection,
    "p-button": Button,
    "p-datatable": DataTable,
    "p-column": Column,
    "p-carousel": Carousel,
    "p-scrollpanel": ScrollPanel,
    "p-textarea": TextArea,
    'p-dialog': Dialog,
    "p-accordion": Accordion,
    "p-accordion-tab": AccordionTab,
  },
  data() {
    return {
      subscriptionModal: false,
      tags: [],
      client: this.initialClient,
      subscription: this.initialSubscription,
      contractOverview: this.initialSubscription["contract_information"] || {},
      contractOverviewKey: 0,
      supportOverview: this.initialSubscription["support_information"] || {
        contracted_support: {
          time_from: "",
          time_to: "",
          timezone: "EST",
          from_day: "MON",
          to_day: "FRI",
        },
        support_email: "support@verto.ca",
      },
      internalContacts:
        this.initialSubscription.contact_priorities.filter(
          (contact_priority) =>
            contact_priority.contact.contact_type == "internal"
        ) || [],
      internalContactFormKey: 0,
      additionalMaterials:
        this.initialSubscription["additional_materials"] || {},
      additionalMaterialsFormKey: 0,
      closeNotesToggle: false,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
          numScroll: 5,
        },
        {
          breakpoint: "600px",
          numVisible: 4,
          numScroll: 4,
        },
        {
          breakpoint: "480px",
          numVisible: 3,
          numScroll: 3,
        },
      ],
    };
  },
  props: {
    initialClient: {
      type: Object,
      require: true,
    },
    initialSubscription: {
      type: Object,
      require: true,
    },
  },
  methods: {
    onExternalContactSaveSuccess(event) {
      this.$refs.contactsAccordion.closeEditModal();
      this.$set(this.subscription, "contact_priorities", event);
      this.$forceUpdate();
    },
    onInternalContactSaveSuccess(event) {
      this.$refs.contactsAccordion.closeEditModal();
      this.internalContacts = JSON.parse(JSON.stringify(event));
      this.internalContactFormKey = !this.internalContactFormKey;
    },
    onSupportSaveSuccess(event) {
      this.$refs.supportAccordion.closeEditModal();
      this.supportOverview = JSON.parse(JSON.stringify(event));
      this.$forceUpdate();
    },

    onZendeskUsersSaveSuccess(event) {
      this.$refs.supportAccordion.closeEditModal();
      this.$set(this.subscription, "contact_priorities", event);
      this.$forceUpdate();
    },
    onAdditionalMaterialsSaveSuccess(event) {
      this.$refs.additionalMaterialsAccordion.closeEditModal();
      this.additionalMaterials = JSON.parse(JSON.stringify(event));
      this.subscription.additional_materials = this.additionalMaterials;
      this.additionalMaterialsFormKey = !this.additionalMaterialsFormKey;
    },
    onSubscriptionSaveSuccess(event){
      this.$set(this.subscription, "description", event.description);
      this.$set(this.subscription, "name", event.name);
      this.$set(this.subscription, "status", event.status);
    },
    onSaveNotesSuccess(event, noteType) {
      this.closeNotesToggle = !this.closeNotesToggle
      this.$set(this.subscription, noteType, JSON.parse(JSON.stringify(event))[noteType])
      this.$forceUpdate();
    },
    closeModals(ref) {
      this.$refs[ref].closeEditModal();
    },
    isEmptyField(field) {
      if (!(field === null || field === undefined)) {
        return field.length === 0;
      } else {
        return true;
      }
    },
    openSubscriptionModal(){
      this.subscriptionModal = true;
    },
    closeSubscriptionModal(){
      this.subscriptionModal = false;
    }
  },
  computed: {
    contractInformationEmpty() {
      return Object.keys(this.contractOverview).length === 0;
    },
    supportInformationEmpty() {
      return Object.keys(this.supportOverview).length === 2;
    },
    sortedContacts() {
      if (this.internalContacts) {
        let array = this.internalContacts;
        let weightMap = {
          primary: 1,
          secondary: 2,
          other: 3,
        };
        array.sort((first, second) => {
          let a = weightMap[first.contact_priority];
          let b = weightMap[second.contact_priority];
          return a - b;
        });
        // return []
        return array.map((contact_priority) =>
          Object.assign(contact_priority.contact, {
            contact_priority: contact_priority.contact_priority,
          })
        );
      } else {
        return [];
      }
    }
  },
  watch: {
    initialClient() {
      this.client = this.initialClient;
    },
    initialSubscription() {
      this.subscription = this.initialSubscription;
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-subheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: baseline;
}

.box {
  border: 1px solid #f2f3f9;
  border-left: 2rem solid skyblue;
  border-radius: 8px;
}

.application-group-name {
  width: 10rem;
  font-size: 1rem;
}

::v-deep .p-scrollpanel {
  .p-scrollpanel-wrapper {
    .p-scrollpanel-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      padding: 0px;

      .application-button {
        margin: 0.21rem;
        a {
          color: white;
          text-decoration: inherit;
        }

        .p-button {
          min-width: max-content;
        }
      }
    }
  }

  .p-scrollpanel-bar {
    background-color: #f2f3f9;
    opacity: 1;
    transition: background-color 0.3s;

    &.p-scrollpanel-hidden {
      height: 0px;
    }
  }

  .p-scrollpanel-bar:hover {
    background-color: #333636;
  }
}
</style>