<template>
  <div class="">
    <div class="row">
      <p-multiselect
          v-model="selectedNamespaces"
          :options="allNamespaces"
          display="chip"
          class="w-100"
          :style="{ 'max-width': '70vw' }"
          :filter="true"
          optionLabel="name"
          @change="onNamespaceChange"/>
    </div>
    <div class="row">
      <p-datatable :value="combinedImplementations" responsiveLayout="scroll">
        <p-column field="container_name" header="Application"></p-column>
        <p-column field="image" header="Image"></p-column>
        <p-column field="semver" header="Version"></p-column>
        <p-column field="domain" header="Domain"></p-column>
      </p-datatable>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import axios from "axios";


export default {
  components: {
    'p-multiselect': MultiSelect,
    'p-datatable': DataTable,
    'p-column': Column,
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedNamespaces: [],
      allNamespaces: [],
    }
  },
  methods: {
    getAllNamespaces() {
      axios.get('/configuration_items?include_resource=true&type=ConfigurationItemTypes::KubernetesNamespace')
          .then((res) => {
            this.allNamespaces = res.data.configuration_items;
          })
          .catch(err => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Error fetching all namespaces'});
          })
    },
    getExistingNamespaces() {
      axios.get(`/configuration_items?include_resource=true&type=ConfigurationItemTypes::KubernetesNamespace&subscription_id=${this.subscription.id}`)
          .then((res) => {
            this.selectedNamespaces = res.data.configuration_items;
          })
          .catch(err => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Error fetching subscription namespaces'});
          })
    },
    onNamespaceChange(event) {
      axios.post('/configuration_items/search_and_unlink_subscriptions', {
        subscription_ids: [this.subscription.id],
        type: 'ConfigurationItemTypes::KubernetesNamespace'
      })
          .then((res) => {
            axios.post('/configuration_items/search_and_link_subscriptions', {
              subscription_ids: [this.subscription.id],
              id: this.selectedNamespaces.map(namespace => namespace.id),
              type: 'ConfigurationItemTypes::KubernetesNamespace'
            })
                .then((res) => {
                  this.$toast.add({severity:'success', summary: 'Success', detail:'Added namespaces', life: 3000});
                })
                .catch((error) => {
                  console.error(error);
                  this.$toast.add({severity:'error', summary: 'Error', detail:'Could not add namespaces', life: 3000});
                })
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({severity:'error', summary: 'Error', detail:'Could not delete existing namespaces', life: 3000});
          })
    }
  },
  mounted() {
    this.getAllNamespaces();
    this.getExistingNamespaces();
  },
  computed: {
    combinedImplementations() {
      let allImplementations = []
      this.selectedNamespaces.map(namespace => {
        allImplementations.push(namespace.resource)
      })

      return allImplementations.flat();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>