<template>
  <div>
    <h2>Application</h2>
    <ul>
      <li v-for="client in applications">
        <a :href="`/applications/${client.id}`">{{ client.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    applications: {
      type: Array,
      require: true
    }
  }
}
</script>