<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div class="title-div mb-4">
      <h3 class="title">Reports</h3>
    </div>
    <div class="flex-row d-flex gap-3">
      <div class="card" style="width: 18rem;" v-for="(report, code) in allReports">
        <i class="report-icon mx-auto mt-4 mb-2" :class="report.icon"></i>
        <div class="card-body">
          <h5 class="card-title">{{ report.name }}</h5>
          <p class="card-text">{{ report.description }}</p>
          <a :href="`reports/${code}`" class="btn btn-primary">Generate</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/styledBreadcrumb.vue";

export default {
  components: {
    'breadcrumbs': Breadcrumbs,
  },
  props: {
    allReports: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Reports",
          url: "/reports",
        },
      ],
    }
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.report-icon {
  font-size: 80px;
}
</style>