<template>
  <div>
    <h2>Welcome to CMDB</h2>
    <p-dropdown
        v-model="selectedCity" :options="cities" optionLabel="name" placeholder="WOW a City"
    />
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    'p-dropdown': Dropdown,
  },
  props: {
    units: {
      type: Array,
      require: true
    },
    encounterId: {
      type: Number,
      required: true
    },
    myUser: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      selectedCity: null,
      cities: [
        {name: 'XXNew York', code: 'NY'},
        {name: 'XXRome', code: 'RM'},
        {name: 'XXLondon', code: 'LDN'},
        {name: 'XXIstanbul', code: 'IST'},
        {name: 'XXParis', code: 'PRS'}
      ]
    }
  }
}
</script>