<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-12">
        <h6>Group Name</h6>
        <p-auto-complete
          v-model="client"
          :suggestions="filteredClients"
          @complete="searchClient"
          field="name"
        ></p-auto-complete>
      </div>
    </div>
<!--    <div class="row mb-4">-->
<!--      <div class="col-md-12">-->
<!--        <h6>Group Description</h6>-->
<!--        <p-textarea class="w-100" type="text" :autoResize="true" rows="3" cols="25" v-model="clientDescription"></p-textarea>-->
<!--      </div>-->
<!--    </div>-->

    <div class="row mb-4">
      <div class="col-md-4">
        <h6>Start Date</h6>
        <p-calendar v-model="clientStartDate" class="w-100" />
      </div>
<!--      <div class="col-md-4">-->
<!--        <h6>Organization Short Code</h6>-->
<!--        <p-inputtext v-model="clientIdentifier"></p-inputtext>-->
<!--      </div>-->
<!--      <div class="col-md-4">-->
<!--        <h6>Organization ID</h6>-->
<!--        <p-inputtext type=number v-model="clientExternalId"></p-inputtext>-->
<!--      </div>-->
    </div>

    <div class="my-5">
      <div class="row mb-4">
        <div class="col-md-12">
          <h6>Subscription</h6>
          <p-inputtext
            v-model="newSubscription.name"
            class="w-100"
          ></p-inputtext>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <h6>Subscription Description</h6>
          <p-textarea class="w-100" type="text" v-model="newSubscription.description"></p-textarea>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-4">
          <h6>Subscription Identifier <small>(xxx_001)</small></h6>
          <p-inputtext v-model="newSubscription.identifier"></p-inputtext>
        </div>
        <div class="col-md-4">
          <h6>Subscription Hubspot ID</h6>
          <p-inputtext type=number v-model="newSubscription.external_id"></p-inputtext>
        </div>
<!--        <div class="col-md-4">-->
<!--          <h6>Subscription Dev Code</h6>-->
<!--          <p-inputtext type=number v-model="newSubscription.dev_code"></p-inputtext>-->
<!--        </div>-->
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <h6>Status:</h6>
          <p-dropdown
            v-model="newSubscription.status"
            :options="initialStatuses"
            optionLabel="label"
            optionValue="value"
            class="w-100"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';

export default {
  components: {
    'p-button': Button,
    'p-inputtext': InputText,
    'p-textarea': Textarea,
    'p-dropdown': Dropdown,
    'p-auto-complete': AutoComplete,
    'p-calendar': Calendar,
  },
  data: function() {
    return {
      clientDescription: '',
      clientIdentifier: '',
      clientExternalId: null,
      clientStartDate: new Date(),
      newClient: {
        name: '',
        description: '',
        owner_id: 1,
      },
      newSubscription: {
        name: '',
        description: '',
        status: '',
        identifier: '',
        external_id: null,
        dev_code: '',
      },
      subscriptionName: '',
      filteredClients: [],
      isNewClient: true,
      subscription: null,
      client: null,
    }
  },
  props: {
    initialClients: {
      type: Array,
      required: true
    },
    initialStatuses: {
      type: Array,
      required: true
    },
    shouldSave: {
      type: Boolean,
      required: false
    },
    resetValues: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    searchClient(event) {
      this.filteredClients = this.initialClients.filter((client) => client['name'].toLowerCase().includes(event.query.toLowerCase()));
    },
    async saveClient() {
      this.newClient['name'] = this.client;
      this.newClient['description'] = this.clientDescription;
      this.newClient['identifier'] = this.clientIdentifier;
      this.newClient['external_id'] = this.clientExternalId;
      this.newClient['start_date'] = this.clientStartDate;
      return new Promise((resolve, reject) => {
        axios.post('/clients', this.newClient)
        .then(res => {
          this.$toast.add({severity:'success', summary: 'Success', detail:'Created group.', life: 3000});
          this.client = res.data.client;
          this.clientDescription =  res.data.client.description;
          resolve();
        })
        .catch((error) => {
          let errorData = error.response.data.errors
          let errorMessage = `${Object.keys(errorData)[0]}: ${Object.values(errorData)[0]}`
          this.$toast.add({severity:'error', summary: 'Error', detail: errorMessage, life: 3000});
          this.$emit('save-fail', error);
        })
      })
    },
    createSubscription(event) {
      let payload = {
        client: { id: this.client.id, description: this.clientDescription},
        subscription: this.newSubscription
      }
      return new Promise((resolve, reject) => {
        axios.post('/subscriptions', payload)
        .then((res) => {
          this.$toast.add({severity:'success', summary: 'Success', detail:'Created Subscription.', life: 3000});
          this.subscription = res.data.subscription;
          resolve();
        })
        .catch((error) => {
          let errorData = error.response.data.errors
          let errorMessage = `${Object.keys(errorData)[0]}: ${Object.values(errorData)[0]}`
          this.$toast.add({severity:'error', summary: 'Error', detail: errorMessage, life: 3000});
          this.$emit('save-fail', error);
        })
      })
    },
  },
  watch: { 
    client() {
      let clientName = typeof this.client === 'string' ? this.client : this.client.name;
      let existingClient = this.initialClients.find((client) => client.name.toLowerCase() === clientName.toLowerCase());
      if (existingClient) {
        this.clientDescription = existingClient.description;
        this.isNewClient = false;
      } else {
        this.isNewClient = true;
      }
    },
    shouldSave: async function(newUpdateRequest) {
      if (newUpdateRequest === true) {
        if(this.isNewClient) {
          await this.saveClient();
        }
        await this.createSubscription();
        this.$emit('save-success');
      }
    },
    resetValues: function(newReset) {
      if (newReset === true) {
        this.client = {};
        this.clientDescription = '';
        this.subscriptionName = '';
        this.newClient.status ='';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .p-inputtext {
    width: 100%;
  }

  ::v-deep .p-autocomplete {
    width: 100%;
  }
</style>