<template>
  <span v-if="tooltip">
    <span v-tooltip.bottom="moment(datetime).strftime('%a, %e %b %Y %H:%M:%S %z')" >
      {{ moment(datetime).strftime('%b %d, %Y') }}
    </span>
  </span>
  <span v-else>
    <span>
      {{ moment(datetime).strftime('%b %d, %Y') }}
    </span>
  </span>

</template>
<script>
import moment from 'moment';
require('moment-strftime');

export default {
  data() {
    return {
      moment
    }
  },
  props: {
    datetime: {
      require: true
    },
    tooltip: {
      require: false,
      default: true
    }
  }
}
</script>