<template>
  <div>
    <div class="float-label">
      <label>Implementation Name:</label>
      <p-inputtext v-model="newImplementation.name"></p-inputtext>
    </div>
    <div class="float-label">
      <label>Subscriptions:</label>
      <p-dropdown
        v-model="newImplementation.subscription_id"
        :options="initialSubscriptions"
        optionLabel="label"
        optionValue="id"
        class="w-100"
      ></p-dropdown>
    </div>
    <div>
      <p-button class="save-btn" label="Save" @click="saveEditImplementation"></p-button>
      <p-button class="p-button-danger" label="Delete" @click="deleteImplementation"></p-button>
      <p-button class="cancel-btn" label="Cancel"></p-button>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';

export default {
  components: {
    "p-dropdown": Dropdown,
    "p-autocomplete": AutoComplete,
    "p-inputtext": InputText,
    "p-button": Button,
  },
  props: {
    initialImplementation: {
      type: Object,
      default:() => {}
    },
    initialSubscriptions: {
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
      filteredApplications: [],
      suggestedApplications: [
        { name: "Flow", description: "Flow" },
        { name: "Automate", description: "Automate" },
        { name: "Engage", description: "Engage" },
        { name: "Journey", description: "Journey" },
      ],
      newImplementation: {
        name: "",
        applications_attributes: [],
        subscription: "",
        subscription_id: "",
      },
    }
  },
  methods: {
    searchApplication(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredApplications = [...this.suggestedApplications];
        } else {
          this.filteredApplications = this.suggestedApplications.filter(
            (application) => {
              return application.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            }
          );
        }
      }, 250);
    },
    saveEditImplementation() {
      this.$emit('save-edited-implementation', this.newImplementation)
    },
    deleteImplementation() {
      this.$emit('delete-implementation', this.newImplementation)
    }
  },
  mounted() {
    if(this.initialImplementation) {
      this.newImplementation = this.initialImplementation;
    }
  }
}

</script>