<template>
  <div>
    <div>
      <h5>Add Verto Contact</h5>
      <div class="row mb-4">
        <div class="col-md-6">
          <h6>Primary contact</h6>
          <p-dropdown
            class="w-100"
            v-model="vertoPrimaryContact"
            :options="vertoContacts"
            optionValue="id"
            optionLabel="first_name"
            showClear
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }} - {{ slotProps.option.email}}</span>
            </template>
          </p-dropdown>
        </div>
        <div class="col-md-6">
          <h6>Secondary contact</h6>
          <p-dropdown
            class="w-100"
            v-model="vertoSecondaryContact"
            :options="vertoContacts"
            optionValue="id"
            optionLabel="first_name"
            showClear
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }} - {{ slotProps.option.email}}</span>
            </template>
          </p-dropdown>
        </div>
      </div>
      <h6>Other contacts</h6>
      <p-listbox
        class="mb-5"
        v-model="vertoOtherContacts"
        :options="vertoContacts"
        key="id"
        optionLabel="id"
        optionValue="id"
        filterPlaceholder="Search"
        :multiple="true"
      >
      <template #option="slotProps">
        <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }} - {{ slotProps.option.email}}</span>
      </template>
    </p-listbox>
    </div>
    <div>
      <h5>Add External Contact</h5>
      <div class="row mb-4">
        <div class="col-md-6">
          <div class="row mb-4">
            <div class="col-md-6">
              <h6>First Name</h6>
              <p-input-text type="text" v-model="contact.first_name" class="w-100" />
            </div>
            <div class="col-md-6">
              <h6>Last Name</h6>
              <p-input-text type="text" v-model="contact.last_name" class="w-100" />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-6">
              <h6>Title</h6>
              <p-input-text type="text" v-model="contact.title" class="w-100" />
            </div>
            <div class="col-md-6">
              <h6>Contact priority</h6>
              <p-dropdown
                v-model="contact.contact_priority"
                :options="contact_priorities"
                optionLabel="label"
                optionValue="value"
              ></p-dropdown>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12">
              <h6>Email</h6>
              <p-input-text type="text" v-model="contact.email" class="w-100" />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12">
              <h6>Phone Number</h6>
              <p-input-text type="text" v-model="contact.phone_number" class="w-100" />
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-12 d-flex flex-row-reverse">
              <p-button
                label="Add contact"
                @click="addExternalContact"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Listbox from 'primevue/listbox';

export default {
  components: {
    'p-button': Button,
    'p-input-text': InputText,
    'p-dropdown': Dropdown,
    'p-listbox': Listbox,
  },
  data: function() {
    return {
      contact: {},
      internalContacts: this._.clone(this.initialInternalContacts),
      contact_priorities: [
        { label: 'Primary', value: 'primary' },
        { label: 'Secondary', value: 'secondary' },
        { label: 'Other', value: 'other' },
      ],
      vertoPrimaryContact: null,
      vertoSecondaryContact: null,
      vertoOtherContacts: [],
      vertoContacts: [],
      internalContactsChange: false,
    }
  },
  props: {
    initialInternalContacts: {
      type: Array,
      required: false
    },
    parentModel: {
      type: String,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    shouldSave: {
      type: Boolean,
      required: false
    },
    resetInfo: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    getInternalContacts() {
      axios.get('/contacts')
      .then((res) => {
        this.vertoContacts = res.data.contacts;
      })
      .catch((error) => {
        this.$toast.add({severity:'error', summary: 'Error', detail:'Could not reach verto contact list.', life: 3000});
      })
    },
    removeIdFromArray(array, id) {
      let targetIndex = array.findIndex((obj) =>　{
        if(typeof obj === 'number') {
          return obj == id;
        } else {
          return obj['contact_id'] == id;
        }
      });
      if(targetIndex > -1) {
        array = array.splice(targetIndex, 1)
      }
    },
    setInitialKeyContacts() {
      if(this.internalContacts) {
        this.vertoPrimaryContact = (this.internalContacts.find(contact => contact.contact_priority == 'primary')) ?
          (this.internalContacts.find(contact => contact.contact_priority == 'primary'))['contact_id'] :
          null;
        this.vertoSecondaryContact = (this.internalContacts.find(contact => contact.contact_priority == 'secondary')) ?
          (this.internalContacts.find(contact => contact.contact_priority == 'secondary'))['contact_id'] :
          null;
        this.vertoOtherContacts = (this.internalContacts.filter(contact => contact.contact_priority == 'other')).map(contact => contact['contact_id'])
      }
    }
  },
  watch: {    
    shouldSave: function(newUpdateRequest) {
      if (newUpdateRequest === true) {
        this.saveContacts();
      }
    },
    resetInfo: function(newReset) {
      if (newReset === true) {
        this.setInitialKeyContacts();
        this.internalContacts = this._.clone(this.initialInternalContacts)
      }
    },
  },
  mounted() {
    this.getInternalContacts();
    this.setInitialKeyContacts();
  }
}
</script>

<style lang="scss" scoped>
  .external-contact-box {
    padding: 0.5rem;
    margin: 0.1rem;
    width: 100%;
    border: 1px solid grey;
    border-radius: 30px;
  }

  ::v-deep .p-listbox .p-listbox-list {
    padding: 0;
  }

</style>