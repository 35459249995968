<template>
  <div class="d-flex align-items-stretch flex-wrap" style="margin-right: -8px;">
    <div v-for="provider in providers" class="me-2 mb-3" style="width: calc(calc(100%/4) - 8px)" @click="handleTransition(provider.slug)">
      <p-card :class="{ disabled: provider.slug === 'disabled' }">
        <template #content>
          <div class="card-icon" :class="provider.icon"></div>
          <div class="card-title">{{ provider.name }}</div>
        </template>
      </p-card>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';

export default {
  components: {
    'p-card': Card
  },
  data: function () {
    return {
      providers: [
        {
          name: 'Azure Resource',
          icon: 'fab fa-microsoft',
          slug: 'azureResource'
        },
        {
          name: 'Azure Resource Group',
          icon: 'fas fa-dice-d6',
          slug: 'azureResource'
        },
        {
          name: 'Generic Configuration Item',
          icon: 'fas fa-link',
          slug: 'azureResource'
        },
        {
          name: 'SendGrid Sub-account',
          icon: 'fas fa-envelope',
          slug: 'disabled'
        },
        {
          name: 'Twilio Sub-account',
          icon: 'fas fa-sms',
          slug: 'disabled'
        },
        {
          name: 'Hubspot',
          icon: 'fab fa-hubspot',
          slug: 'disabled'
        },
        {
          name: 'Google Drive document',
          icon: 'fab fa-google-drive',
          slug: 'disabled'
        }
      ]
    }
  },
  methods: {
    handleTransition: function(type) {
      if (type === 'disabled') return false;
      this.$emit('transition', type)
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
  .card-icon {
    font-size: 64px;
    color: #0d6efd;
    text-align: center;
    width: 100%;
  }

  .card-title {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -5px;
  }

  .p-card.p-component {
    height: 100%;

    &.disabled {
      background-color: #cccccc;
      .card-icon {
        color: grey;
      }

      &:hover {
        background-color: #cccccc;
        cursor: default;
      }
    }

    &:hover {
      background-color: #c9c9c973;
      cursor: pointer;
    }
  }
</style>