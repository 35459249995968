export default {
  props: {
    reportName: {
      type: String,
      required: true
    },
    reportData: {
      type: Array,
      required: true
    }
  }
};
