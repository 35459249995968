<template >
  <div class="datatable">
    <p-datatable
      :value="implementations"
      :paginator="true"
      :rows="10"
      :filters="filters"
      filter-display="menu"
      paginatorPosition="top"
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="{first} - {last} of {totalRecords}"
    >
      <template #FirstPageLink>
        <p-button type="button" icon="pi pi-search" />
      </template>
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <p-inputtext
            v-model="filters['global'].value"
            placeholder="Start typing to search"
          ></p-inputtext>
        </span>
        <p-button
          label="+ Add Implementation"
          @click="openAddImplementationModal"
        ></p-button>
      </template>
      <p-column field="implementation" header="Implementation" sortable>
        <template #body="{ data }">
          <a :href="`/application_groups/${data.implementationId}`">{{ data.implementation }}</a>
        </template>
      </p-column>
      <p-column field="applications" header="Applications" sortable>
        <template #body="{ data }">
          <span
            v-for="(application, key) in data.applications"
            :key="key"
            :class="'datatable-badge app-' + application.name"
          >
            {{ application.name }}
          </span>
        </template>
      </p-column>
      <p-column field="tags" header="Tags" sortable>
        <template #body="{ data }">
          <span
            v-for="(tag, key) in data.tags"
            :key="key"
            class="datatable-badge"
            :style="{ backgroundColor: '#' + tag.color }"
          >
            {{ tag.name }}
          </span>
        </template>
      </p-column>
      <p-column field="subscription" header="Subscription" sortable></p-column>
      <p-column>
        <template #body="{ data }"><p-button type="button" icon="pi pi-pencil" class="edit-button" @click="openEditImplementationModal(data)"></p-button></template>
      </p-column>
    </p-datatable>
  </div>
</template>
<script>

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row"; //optional for row
import InputText from "primevue/inputtext";
import Button from "primevue/button";

import { FilterMatchMode } from "primevue/api";

export default {
  components: {
    "p-datatable": DataTable,
    "p-column": Column,
    "p-columngroup": ColumnGroup,
    "p-row": Row,
    "p-inputtext": InputText,
    "p-button": Button,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  props: {
    implementations: {
      type: Array,
      required: true,
    },
  },

  methods: {
    openAddImplementationModal() {
      this.$emit("open-add-implementation-modal");
    },
    openEditImplementationModal(data) {
      this.$emit("open-edit-implementation-modal", data);
    },
  },

  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
  .app-Flow {
    background-color: #c9e2ff;
    color: #000000;
  }

  .app-Automate {
    background-color: #aeb8ff;
    color: #000000;
  }

  .app-Engage {
    background-color: #f8edac;
    color: #000000;
  }
</style>
